<template>
  <div>
    <div class="registry__container">
      <img class="logo" :src="require('@/assets/logo.png')" />
      <div class="registry__header">
        <h4>Регистрация</h4>
      </div>
      <ModalCode
        v-if="modal_code_show"
        :email="email"
        :phone="phone"
        :login="login"
        @step="func_update_step"
      />
      <div :class="{ min_heigth: !msg }">
        <div class="registry__error" v-show="msg">
          {{ msg }}
        </div>
      </div>
<div style="color: rgb(192 192 192)"> Регистрация не доступна в данном кабинете, используйте новый личный кабинет по адресу  </div>
<div style="color: rgb(192 192 192);margin: 10px;text-align: center;" >www.cart.pcbchina.ru</div>
      <div class="auth__form_bottom_text">
        <span class="btn" @click="$router.push('/auth')">Уже есть логин</span>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCode from "@/components/Registry/ModalCode.vue";
export default {
  components: {
    ModalCode,
  },
  props: {
    p: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    login: "",
    password: "",
    password2: "",
    phone: "",
    email: "",
    promo_show: false,
    promo_msg: "",
    promo: "",
    msg: "",
    modal_code_show: false,
  }),
  methods: {
    func_update_step(step) {
      if (step === 2) {
        let url = "user.php/login";
        let param = {
          login: this.login,
          password: this.password,
        };
        this.$sendRequest(url, param).then((result) => {
          if (result.key) {
            localStorage.setItem("key", result.key);
            this.$sendRequest("order.php/get_param_user", {}).then((result) => {
              if (!result) return;
              result.row = result.row === "true" ? true : false;
              result.anim = result.anim === "1" ? true : false;
              this.$store.commit("CHANGE_USER", result);
              if (result.fields) {
                this.$store.commit("CHANGE_FIELDS", result.fields);
              }
              this.$emit("change_step", step);
            });
          }
        });
      } else {
        this.$emit("change_step", step);
      }
    },
    func_check_promo() {
      this.$sendRequest("user.php/check_promo", {
        promo: this.promo,
      }).then((result) => {
        if (result) {
          this.promo_msg = result.title;
        } else {
          this.promo_msg = "";
        }
      });
    },
    func_register() {
      localStorage.clear();
      if (this.password === this.password2) {
        this.$sendRequest("user.php/registry1", {
          login: this.login,
          password: this.password,
          email: this.email,
          promo: this.promo,
          phone: this.phone,
        }).then((result) => {
          this.msg = result.msg;
          this.modal_code_show = true;
        });
      } else {
        this.msg = "Пароли не совпадают";
      }
    },
  },
  mounted() {
    if (this.$route.query.p) {
      this.promo_show = true;
      this.promo = this.$route.query.p;
    }
  },
  watch: {
    promo(val) {
      if (val.length > 3) {
        this.func_check_promo();
      }
    },
  },
};
</script>

<style></style>
