<template>
  <div class="masters__wrapper">
    <Loader :show="progress" />
     <div class="masters_header">
       <h4>Редактор мастеров</h4>
     </div>
   <div class="master_settings">
     <div class="master_add_form">
       <form @submit.prevent="func_save">
         <input type="text" v-model="master" required />
         <button><i class="fa fa-plus" aria-hidden="true"></i></button>
       </form>
     </div>
     <div class="masters">
       <div class="master__item" v-for="item in masters_list" :key="item.id">
         <span>{{ item.value }}</span>
         <button @click="func_remove(item)">
           <i class="fa fa-times"></i>
         </button>
       </div>
     </div>
   </div>
  </div>
</template>

<script>
import Loader from "@/components/loader";
export default {
  components: {
    Loader,
  },

  data() {
    return {
      master: "",
      masters_list: [],
      progress: true,
    };
  },
  methods: {
    func_save() {
      if (!this.master && this.master === "") return;
      let url = "order.php/add_master";
      this.$sendRequest(url, { value: this.master }).then((result) => {
        if (!result) return;
        this.masters_list.push(result);
        this.master = "";
      });
    },

    func_get_masters() {
      this.progress = true;
      let url = "order.php/get_masters";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.masters_list = result;
        this.progress = false;
      });
    },
    func_remove(item) {
      if (!item) return;
      let url = "order.php/remove_master";
      this.$sendRequest(url, { id: item.id }).then(() => {
        this.masters_list.splice(
          this.masters_list.findIndex((x) => x.id === item.id),
          1
        );
      });
    },
  },
  mounted() {
    this.func_get_masters();
  },
};
</script>

<style></style>
