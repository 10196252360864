<template>
  <div class="notification__wrap">
    <div class="notice">
      <p>{{ text }}</p>
      <button @click="$emit('close')">Ок</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
  },
};
</script>

<style>
.notification__wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 55;
  justify-content: center;
  align-items: center;
}
.notification__wrap .notice {
  color: #fff;
  width: 400px;
  background: #747474;
  padding: 30px;
  border-radius: 11px;
  text-align: center;
  box-shadow: 0 0 4px 0px black;
}
.notification__wrap .notice p {
  text-align: center;
  font-weight: 500;
}
.notification__wrap .notice button {
  border: 1px solid #fff;
  margin-top: 20px;
  padding: 10px;
  width: 30%;
  transition: 0.3s;
}
.notification__wrap .notice button:hover {
  background: #fff;
  color: #000;
}
</style>