<template>
  <div>
    <div class="step3">
      <div class="step__header">
        <h2>Какой номер заказа и клиента будет следующий?</h2>
      </div>
      <div class="step__form">
        <form @submit.prevent="func_save_num_order">
          <div class="form__group">
            <label>Следующий номер заказа</label>
            <div class="registry__form_input">
              <input type="number" required v-model="num_order" />
            </div>
          </div>
          <div class="form__group">
            <label>Следующий номер клиента</label>
            <div class="registry__form_input">
              <input type="number" required v-model="num_client" />
            </div>
          </div>
          <div class="info">
            <p>
              Указав число 1, начало нумерации квитанции и заказов будет 1..2..3
            </p>

            <p>
              Каждому клиенту присваивается свой ID.<br />Указав число 1, ID
              добавленных клиентов будет 1..2..3
            </p>
            <p>
              Если ранее вы пользовались другой CRM системой, вы сможете
              продолжить нумерацию ваших клиентов и заказов.
            </p>
            <p>
              Если вы пользуйтесь системой впервые, оставьте число 1 и нажмите
              продолжить.
            </p>
          </div>
          <div class="buttons">
            <a class="button" @click="func_back">&lt;&lt; Назад</a>
            <button type="submit">Продолжить &gt;&gt;</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    num_order: 1,
    num_client: 1,
  }),
  methods: {
    func_save_num_order() {
      this.$sendRequest("order.php/save_num_object", {
        order_num: this.num_order,
        customer_num: this.num_client,
      }).then(() => {
        this.$emit("change_step", 4);
      });
    },
    func_get_num_order() {
      this.$sendRequest("order.php/get_num_object", {}).then((result) => {
        if (!result) return;
        this.num_order =
          Number(result.num_order) > 0 ? result.num_order : this.num_order;
        this.num_client =
          Number(result.num_client) > 0 ? result.num_client : this.num_client;
      });
    },
    func_back() {
      this.$emit("change_step", 2);
    },
  },
  mounted() {
    this.func_get_num_order();
  },
};
</script>

<style></style>
