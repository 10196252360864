<template>
  <div>
    <div class="order-table">
      <Notice
        v-if="notice.show"
        :text="notice.text"
        @close="func_close_notice"
      />


<div class="main-btn-group clearfix">
        <label>Способ доставки</label>
        <div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="green1 btnadd btnlg"
              :class="{
                cur: this.tipdost == 'СДЕК',
              }"
              @click="func_select_tipdost('СДЕК')"
            >
            <img src="@/assets/post1.png" class="img-add-b" />
              <span class="no-ver">СДЕК</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="purple btnadd btnlg"
             :class="{ cur: this.tipdost == 'Почта России' }"
              @click="func_select_tipdost('Почта России')"
            >
             <img src="@/assets/post2.png" class="img-add-b" />
              <span class="no-ver">Почта России</span>
             
            </button>
          </div>
          
          
          
        </div>


      </div>

      <div class="main-btn-group clearfix">
        <label>
          <font style="vertical-align: inherit"
            ><font style="vertical-align: inherit"
              >ФИО:
            </font></font
          >
        </label>
        <div class="input-group" style="display: flex">
          <div class="quote-input el-input el-input--mini" style="width: 62px">
            <input
               type="text"
               style="width:300px"
               class="el-input__inner dost-inner "
            required
            v-model="profile.company"
            @change="func_save_profile"
              
            />
          </div>
          
        </div>
      </div>

      <div class="main-btn-group clearfix">
        <label>
          <font style="vertical-align: inherit"
            ><font style="vertical-align: inherit"
              >Телефон:
            </font></font
          >
        </label>
        <div class="input-group" style="display: flex">
          <div class="quote-input el-input el-input--mini" style="width: 62px">
            <input
             :mask="['+7 (###) ###-##-##', '+7 (###) ###-##-##']"
               type="text"
               style="width:300px"
               class="el-input__inner dost-inner "
                placeholder="+7 (000) 000 00 00"
            required
            v-model="profile.phone"
            @change="func_save_profile"
              
            />
          </div>
          
        </div>
      </div>

      <div class="main-btn-group clearfix">
        <label>
          <font style="vertical-align: inherit"
            ><font style="vertical-align: inherit"
              >Адрес доставки:
            </font></font
          >
        </label>
        <div class="input-group" style="display: flex">
          <div class="quote-input el-input el-input--mini" style="width: 62px">
            <input
               type="text"
               style="width:500px"
               class="el-input__inner dost-inner "
            required
            v-model="profile.city"
            @change="func_save_profile"
              
            />
          </div>
          
          <div
            class="mask-text-dil"
            v-if="tipdost != 'СДЕК'"
          >
            Укажите полный адрес доставки и индекс. 
            Доставка оплачивается при получении по тарифу Почты России.
            <p style=" color: #bf4646; font-weight:500;"> Внимание! Платная доставка! Долгая доставка!</p>
          </div>
          <div class="mask-text-dil" v-else>
            Укажите адрес пунта выдачи СДЕК. Адреса пунктов выдачи 
            <div class="sdek" @click="func_show_sdek">можно посмотреть тут</div>
         
          </div>
        </div>
        
      </div>
<br>
     <hr>
<br>

      <div class="text-opt-ord">1. Упакуйте gerber файлы в архивы .zip</div>
      <div class="text-opt-ord">
        2. Переименуйте их последовательно 1...2...3...4...5 и тд.
      </div>
      <div class="text-opt-ord">3. Создайте текстовый документ</div>
      <div class="text-opt-ord">
        4. Обязательно укажите размеры платы, их количество и не стандартные
        настройки
      </div>
      <div class="text-opt-ord">
        5. Вы так же можете прикрепить скрин настроек производства JLC
      </div>
      <div class="img-add-a-opt">
        <img src="@/assets/nast.jpg" class="img-add-b-2" />
      </div>
      <div class="text-opt-ord">6. Упакуйте все файлы в архив .zip</div>
      <div class="text-opt-ord">7. Не называйте архив русскими буквами</div>
      <div class="text-opt-ord">
        8. Не делайте название архива очень длинным
      </div>
      <div class="text-opt-ord">
        9. По производству мы будем ориентироватся по текстовому документу или
        скриншотам JLC
      </div>
      <div class="img-add-a-opt">
        <img src="@/assets/nast2.jpg" class="img-add-b-2" />
      </div>

      <div class="text-opt-ord">
        10. Загрузите архив ниже и нажмите создать заказ.
      </div>

      <div class="main-btn-group clearfix">
        <label> Дополнительные настройки </label>
        <div class="dot-set-a">
          <div class="dop-set">
            <textarea
              style="width: 100%"
              rows="3"
              v-model="setting_info"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="group__list" style=" margin-top: 10px; ">
        <div class="down-l" @click="func_update_gerber()">
          <div class="icon">
            <i aria-hidden="true" class="fa fa-cloud-upload"></i>
          </div>
          <div class="text">Загрузить gerber</div>
        </div>
        <input
          type="file"
          id="file"
          ref="file"
          v-on:change="func_file_upload()"
          style="display: none"
          accept=".zip"
        />
        <div class="finish-work" v-if="this.file != '' && !this.loader_file">
          <div class="finish-work-line">
            <div class="finish-work-f" :style="{ width: loader + '%' }"></div>
          </div>
        </div>

        <div class="text-download" v-if="this.file == ''">
          Загрузите Gerber файлы в архиве .zip
        </div>
        <div class="text-download2" v-if="this.file == ''">
          Максимальный размер файла 10мб
        </div>
        <div
          class="text-down-finish"
          v-if="this.file != '' && this.loader_file"
        >
          Загружено: {{ this.file.name }}
          <a class="del-file" @click="func_delete_file()">
            <i aria-hidden="true" class="fa fa-times"></i
          ></a>
        </div>
        <br />
      </div>

      <div class="header__product__title" style="padding-top: 10px">
        <div
          :style="{ display: send_order ? 'none' : 'block' }"
          class="send-ord"
          @click="func_set_order"
        >
          <div class="icon">
            <i aria-hidden="true" class="fa fa-angle-double-down"></i>
          </div>

          <div class="text">Создать заказ</div>
        </div>

        <div
          class="send-ord-no"
          :style="{ display: send_order ? 'flex' : 'none' }"
        >
          <div class="icon">
            <div class="loader__wrapper"><div class="loading1"></div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notice from "@/components/notification.vue";
export default {
  components: {
    Notice,
  },
  data() {
    return {
       profile: {},
      setting_info: "",
      file: "",
      loader: 0,
      tipdost: "СДЕК",
      loader_file: false,
      send_order: false,
      mak: "Оптовый заказ PCB",
      notice: {
        show: false,
        text: "",
      },
      interval: null,
    };
  },
  methods: {

 func_get_profile() {
      this.progress = true;
      let url = "order.php/get_profile";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.profile = result;
        this.progress = false;
      });
    },
func_save_profile() {
      let url = "order.php/save_profile";
      this.$sendRequest(url, this.profile);
    },
    func_save_title() {
      let url = "order.php/save_profile_title";
      this.$sendRequest(url, {
        title: this.profile.title,
      }).then((result) => {
        if (!result) return;
        if (result.success) this.msg = result.msg;
        setTimeout(() => {
          this.msg = "";
        }, 2000);
      });
    },
    func_show_sdek() {
      window.open("https://www.cdek.ru/ru/offices", "_blank");
    },

    func_file_upload() {
      this.file = this.$refs.file.files[0];
      if (this.file.size / 1024 / 1024 > 10) {
        this.file = "";
        this.loader = 0;
        this.loader_file = false;
        this.notice = {
          show: true,
          text: "Файл больше 10 мб",
        };
        return;
      }
      if (!this.file) return;
      this.loader = 0;
      this.loader_file = false;
      this.interval = setInterval(() => {
        this.loader += 1;
        if (this.loader >= 100) {
          this.loader = 100;
          this.loader_file = true;
          clearInterval(this.interval);
        }
      }, 10);
    },
    func_select_tipdost(tipdost) {
      this.tipdost = tipdost || "";
    },
    func_set_order() {
      if (!this.file) {
        this.notice = {
          show: true,
          text: "Выберите файл",
        };
        return;
      }

      this.send_order = true;
      let json = {
        mak: this.mak,
        tipdost: this.tipdost,
        setting_info: this.setting_info,
        file: this.file,
        path: "files",
      };
      this.$sendRequest("order.php/save_order", json).then((result) => {
        if (result) {
          this.func_delete_file();
          this.$router.push("/");
        }
      });
    },
    func_delete_file() {
      this.file = "";
      this.loader = 0;
      this.loader_file = false;
    },
    func_get_default() {
      let result = false;
      if (
       this.tip == "Нет"
      ) {
        result = true;
      }
      return result;
    },
    func_update_gerber() {
      document.querySelector("#file").setAttribute("type", "text");
      document.querySelector("#file").setAttribute("type", "file");
      document.querySelector("#file").click();
    },
    func_close_notice() {
      this.notice = {
        show: false,
        text: "",
      };
    },
   
  },
   mounted() {
    this.func_get_profile();
  },
};
</script>

<style>
</style>