<template>
  <div class="header__comment__wrapper">
    <div class="adm-uv" v-if="title">
      {{ title }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      title: "",
    };
  },
  computed: {
    ...mapGetters(["USER_STATE"]),
  },
  methods: {
    func_show_plus_balance() {
      this.$router.push("/setting");
    },
    func_get_title() {
      let url = "order.php/get_title";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.title = result;
      });
    },
  },
  mounted() {
    this.func_get_title();
  },
};
</script>

<style></style>
