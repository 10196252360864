<template>
  <div class="setting__user__wrapper">
    <div
      class="close__btn tooltip"
      @click="
        USER_STATE.count_day === 0
          ? $emit('close_setting_user')
          : $router.push('/')
      "
      tooltip="Назад"
      tooltip-position="bottom left"
    >
      <i class="fa fa-external-link" aria-hidden="true"></i>
    </div>
    <div class="setting__link">
      <div
        class="item__link"
        v-for="item in links.filter((x) => x.hidden === false)"
        :key="item.id"
        :class="{ active: item.active }"
        @click="func_select_link(item)"
      >
        <div class="content">
          <div class="img">
            <i class="fa" :class="[item.css]" aria-hidden="true"></i>
          </div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div class="setting__body">
      <Podpis v-if="selected_id === 1" />
      <Func v-if="selected_id === 2" />
      <Profile v-if="selected_id === 3" />
      <Devices v-if="selected_id === 4" />
      <Status v-if="selected_id === 5" />
      <Masters v-if="selected_id === 6" />
      <FieldsName v-if="selected_id === 7" />
    </div>
  </div>
</template>
<script>
import Podpis from "@/components/SettingUser/podpis.vue";
import Devices from "@/components/SettingUser/devices.vue";
import Func from "@/components/SettingUser/func.vue";
import Masters from "@/components/SettingUser/masters.vue";
import Profile from "@/components/SettingUser/profile.vue";
import Status from "@/components/SettingUser/status.vue";
import FieldsName from "@/components/SettingUser/field_names.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    Podpis,
    Devices,
    Func,
    Masters,
    Profile,
    Status,
    FieldsName,
  },
  data() {
    return {
      selected_id: 1,
      links: [],
    };
  },
  methods: {
    func_select_link(item) {
      this.links.forEach((obj) => {
        obj.active = false;
      });
      item.active = true;
      this.selected_id = item.id;
    },
    func_get_balance() {
      if (this.USER_STATE.count_day <= 0) {
        this.links = [
          {
            id: 1,
            text: "Подписка",
            css: "fa-rub",
            active: true,
            hidden: false,
          },
        ];
      } else {
        this.links = [
          {
            id: 1,
            text: "Профиль",
            css: "fa-id-card-o",
            active: true,
            hidden: false,
          },
          
        ];
      }
    },
  },
  mounted() {
    this.func_get_balance();
    if (this.$route.params.id) {
      this.func_select_link({ id: this.$route.params.id });
      this.links.find((x) => x.id === this.$route.params.id).active = true;
    }
  },
  computed: {
    ...mapGetters(["USER_STATE"]),
  },
};
</script>

<style></style>
