<template>
  <div class="main__wrapper">
    <div class="main__content">
      <div class="main__header">
        <div class="header__left">
          <img :src="require('@/assets/logo.png')" alt="" class="header_img" />
        </div>
        <div class="header__center">
      
        </div>
        <div class="header__right">
          <a
            class="setting-icon tooltip"
            tooltip="Настройки"
            @click="show_setting = true"
            tooltip-position="left"
          >
            <i class="fa fa-cogs" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <HeaderComment @show_balance_plus="func_show_plus_balance" />
      <FirstModel :progress="progress" :card_list="card_list" />
      <transition name="slide_filter">
        <FilterComponent
          v-if="show_filter"
          @close_filter="func_close_filter()"
          @change_filter="func_change_filter"
        />
      </transition>
      <transition name="setting_fade">
        <Setting
          v-if="show_setting"
          @close_setting="show_setting = false"
          @change_row="func_change_class"
          @show_obj="func_show_page"
        />
      </transition>
      <Loader :show="progress" />
      <div class="data_table" v-if="card_list.length">
        <div class="main__orders">
          <Card
            v-for="item in card_list"
            :key="item.order_id"
            :obj="item"
            @order_del="func_remove_order"
            @show_detail="func_show_detail(item)"
            @show_history="func_show_history(item)"
            @pay="func_pay"
            @show_detailmg="func_show_detailmg(item)"
          />
        </div>

        <transition name="fade_down_detail">
          <Detail
            :order="detail_id"
            v-if="detail_id"
            @close_detail="detail_id = null"
            @change_data="func_change_data"
            @show_order="func_show_order"
            @show_user="func_show_customer"
            :update_detail="update_detail"
          />
        </transition>

        <transition name="fade_down_history">
          <History
            :order="history_id"
            :track="track"
            v-if="history_id"
            @close_history="
              history_id = null;
              track = '';
            "
          />
        </transition>
        <transition name="fade_down_detailmg">
          <Detailmg
            :order="detailmg_id"
            v-if="detailmg_id"
            @close_detailmg="detailmg_id = null"
            @change_data="func_change_data"
            @show_order="func_show_order"
            @show_user="func_show_customer"
            :update_detailmg="update_detailmg"
          />
        </transition>

        <OrderProduct
          v-if="show_order"
          @close_users="func_close_order"
          :product_order="product_order"
        />
      </div>
    </div>
    <transition name="notice_right">
      <NoticeRight v-if="NOTICE_MSG_STATE"
    /></transition>
    <PaymentError v-if="pay_code == 2" @close_pay="func_close_pay" />
    <PaymentSuccess v-if="pay_code == 1" @close_pay="func_close_pay" />
    <transition name="fade_down_detail">
      <Pay v-if="pay_order" :order="pay_order" @close="pay_order = null" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FirstModel from "@/components/Main/first_open_model";
import Card from "@/components/Main/card";
import Detail from "@/components/Main/details";
import History from "@/components/Main/history.vue";
import Detailmg from "@/components/Main/details_img";
import NoticeRight from "@/components/notice.vue";

import Pay from "@/components/Main/pay.vue";
import FilterComponent from "@/components/Main/filter";
import OrderProduct from "@/components/Main/order_products.vue";
import Setting from "@/components/Main/setting";
import HeaderComment from "@/components/Main/header_comment.vue";
import Loader from "@/components/loader";

import PaymentSuccess from "@/components/_Pages/payment_success.vue";
import PaymentError from "@/components/_Pages/payment_error.vue";

export default {
  components: {
    Card,
    Detail,
    Detailmg,
    FilterComponent,
    Setting,
    HeaderComment,
    Loader,
    OrderProduct,
    PaymentSuccess,
    PaymentError,
    History,
    Pay,
    FirstModel,
    NoticeRight,
  },
  data() {
    return {
      card_list: [],
      count_row: 0,
      limit: 500,
      offset: 0,
      detail_id: null,
      history_id: null,
      track: "",
      detailmg_id: null,
      show_add_order: false,
      show_filter: false,
      show_setting: false,
      show_setting_user: false,
      show_user: false,
      show_products: false,
      show_order: false,
      filter_obj: {},
      filter: false,
      show_drop: false,
      progress: true,
      interval: null,
      customer_id_show: null,
      update_detail: false,
      update_detailmg: false,
      product_order: null,
      close_order: true,
      pay_code: 0,
      pay_order: null,
    };
  },
  watch: {
    USER_STATE() {
      this.func_upload_script();
    },
    NOTICE_MSG_STATE(val) {
      if (val) {
        setTimeout(() => {
          this.$store.commit("CHANGE_NOTICE_MSG", "");
        }, 4000);
      }
    },

    show_filter(val) {
      let user_state = this.USER_STATE;
      if (val) {
        this.close_order = user_state.close_order;
        user_state.close_order = false;
        this.$store.commit("CHANGE_USER", user_state);
      } else {
        user_state.close_order = this.close_order;
        this.$store.commit("CHANGE_USER", user_state);
        this.close_order = null;
      }
    },
  },
  methods: {
    func_pay(obj) {
      if (!obj) return;
      this.pay_order = obj;
    },
    func_close_pay() {
      this.pay_code = 0;
      this.$router.push("/");
    },

    func_remove_order(order_id) {
      this.card_list.splice(
        this.card_list.findIndex((x) => x.order_id == order_id),
        1
      );
    },
    iosDate(date) {
      return date ? date.replace(" ", "T") : "";
    },
    func_close_order() {
      this.show_order = false;
      this.update_detail = true;
      setTimeout(() => {
        this.update_detail = false;
      }, 1000);
    },
    func_close_ordermg() {
      this.show_order = false;
      this.update_detailmg = true;
      setTimeout(() => {
        this.update_detailmg = false;
      }, 1000);
    },
    func_add_order_detail(customer) {
      if (!customer) return;
      this.show_customer_detail = false;
      this.func_show_add_client(customer);
    },
    func_show_add_client(customer) {
      this.customer = customer;
      this.show_add_order = true;
    },
    func_show_order(order) {
      this.product_order = order;
      this.show_order = true;
    },
    func_show_more() {
      this.offset++;
      this.func_get_data();
    },

    func_get_data() {
      this.progress = true;
      let url = "order.php/get_order";
      let param = {
        limit: this.limit,
        offset: this.offset,
      };

      this.$sendRequest(url, param).then((result) => {
        if (result && result.data) {
          setTimeout(() => {
            result.data.forEach((item) => {
              this.card_list.push(item);
            });
            this.progress = false;
          }, 500);
          this.count_row = Number(result.count_row);
        }
      });
    },

    func_upload_script() {
      if (this.USER_STATE) {
        if (this.USER_STATE.anim) {
          if (!document.querySelector("canvas")) {
            document.querySelector("body").classList.add("trans");
            setTimeout(() => {
              if (window.bubbly) {
                window.bubbly();
              }
            }, 500);
          }
        } else {
          let canvas_all = document.querySelectorAll("canvas");
          document.querySelector("body").classList.remove("trans");
          if (!canvas_all) return;
          canvas_all.forEach((item) => {
            document.querySelector("body").removeChild(item);
          });
        }
      }
    },

    func_close_filter() {
      this.show_filter = false;
      this.filter_obj = {};
    },
    func_change_data(obj) {
      if (obj) {
        let card = this.card_list.find(
          (x) => Number(x.order_id) === Number(obj.order_id)
        );
        if (card) {
          card.status = obj.status ? obj.status.value : "";
          card.status_id = obj.status ? obj.status.id : "";
          card.status_color = obj.status.status_color;
          card.device = obj.device ? obj.device.value : "";
          card.group_name = obj.group_name || "";
          card.fire = obj.fire;
        }
      }
    },
    func_show_detail(item) {
      if (item) {
        this.detail_id = item;
      }
    },
    func_show_history(item) {
      if (item) {
        this.history_id = item;
        this.track = item.num_departure;
      }
    },
    func_show_detailmg(item) {
      if (item) {
        this.detailmg_id = item;
      }
    },
    func_change_filter(obj) {
      if (obj) {
        if (Number(obj.status_selected) !== 0 || obj.search || obj.date_end) {
          this.filter = true;
          this.filter_obj = obj;
        } else {
          this.filter = false;
          this.filter_obj = {};
        }
      }
    },

    func_change_class(value) {
      let user_setting = this.USER_STATE;
      user_setting.row = value;
      this.$store.commit("CHANGE_USER", user_setting);
    },
    func_show_page(value) {
      if (value === "setting") {
        this.show_add_order = false;
        this.show_setting = false;
        this.show_filter = false;
        this.detail_id = null;
        this.detailmg_id = null;

        this.show_setting_user = true;
      }
    },
    func_save_row() {
      let url = "order.php/save_setting_user";
      let param = {
        row: this.USER_STATE.row === true ? 1 : 0,
      };
      this.$sendRequest(url, param);
    },
    order_link(order_id) {
      this.detail_id = order_id;
    },
    order_link2(order_id) {
      this.detailmg_id = order_id;
    },
    func_show_customer(customer_id) {
      this.customer_id_show = customer_id;
      this.show_user = true;
    },
    func_show_message(item) {
      item.showing = "1";
      let url = "order.php/show_message";
      this.$sendRequest(url, item).then((result) => {
        if (!result) return;
        this.$store.commit("CHANGE_MESSAGE_LIST", result.message_list);
        this.$store.commit(
          "CHANGE_MESSAGE_NO_SHOW",
          Number(result.count_no_show) > 0 ? true : false
        );
      });
    },
    func_drop_message() {
      if (this.MESSAGE_LIST_STATE.length > 0) {
        this.show_drop = !this.show_drop;
        if (this.show_drop) {
          let blur = document.createElement("div");
          blur.classList.add("blur");
          document
            .querySelector("#app")
            .insertBefore(blur, document.querySelector("#app").firstChild);
        } else {
          document
            .querySelector("#app")
            .removeChild(document.querySelector(".blur"));
        }
      }
    },
    func_show_plus_balance() {
      this.show_setting_user = true;
    },
    func_get_message() {
      let url = "order.php/get_message";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.$store.commit("CHANGE_MESSAGE_LIST", result.message_list);
        this.$store.commit(
          "CHANGE_MESSAGE_NO_SHOW",
          Number(result.count_no_show) > 0 ? true : false
        );
      });

      //this.interval = setTimeout(this.func_tick, 10000);
    },
    func_tick() {
      if (this.USER_STATE) {
        let url = "order.php/get_message";
        this.$sendRequest(url, {}).then((result) => {
          if (!result) return;
          this.$store.commit("CHANGE_MESSAGE_LIST", result.message_list);
          this.$store.commit(
            "CHANGE_MESSAGE_NO_SHOW",
            Number(result.count_no_show) > 0 ? true : false
          );
          this.interval = setTimeout(this.func_tick, 10000);
        });
      }
    },
  },
  mounted() {
    if (this.$route.params && this.$route.params.link_order_id) {
      this.order_link(this.$route.params.link_order_id);
    }
    this.func_get_data();
    this.func_upload_script();
    this.func_get_message(this.$route, this.$router);
    if (this.$route.path == "/pay_success") {
      this.pay_code = 1;
    }
    if (this.$route.path == "/pay_error") {
      this.pay_code = 2;
    }
  },
  computed: {
    ...mapGetters(["MESSAGE_NO_SHOW_STATE"]),
    ...mapGetters(["MESSAGE_LIST_STATE"]),
    ...mapGetters(["USER_STATE"]),
    ...mapGetters(["NOTICE_MSG_STATE"]),
    user_row: {
      get() {
        return this.USER_STATE.row;
      },
      set(value) {
        let user_setting = this.USER_STATE;
        user_setting.row = value;
        this.$store.commit("CHANGE_USER", user_setting);
      },
    },
    filter_list() {
      let status = !(
        !this.filter_obj.status_selected ||
        Number(this.filter_obj.status_selected) === 0
      );
      let search = !(!this.filter_obj.search || this.filter_obj.search === "");
      let date_end = !(
        !this.filter_obj.date_end || this.filter_obj.date_end === ""
      );

      let new_list = this.card_list
        .filter((x) => {
          if (status && !search && !date_end) {
            if (
              Number(x.status_id) === Number(this.filter_obj.status_selected)
            ) {
              return true;
            } else {
              return false;
            }
          } else if (status && search && !date_end) {
            if (
              x.phone.indexOf(this.filter_obj.search) !== -1 &&
              Number(x.status_id) === Number(this.filter_obj.status_selected)
            ) {
              return true;
            } else {
              return false;
            }
          } else if (status && search && date_end) {
            if (
              x.phone.indexOf(this.filter_obj.search) !== -1 &&
              Number(x.status_id) === Number(this.filter_obj.status_selected) &&
              new Date(
                this.iosDate(this.filter_obj.date_end)
              ).toLocaleDateString() ===
                new Date(this.iosDate(x.date_create)).toLocaleDateString()
            ) {
              return true;
            } else {
              return false;
            }
          } else if (!status && search && date_end) {
            if (
              x.phone.indexOf(this.filter_obj.search) !== -1 &&
              new Date(
                this.iosDate(this.filter_obj.date_end)
              ).toLocaleDateString() ===
                new Date(this.iosDate(x.date_create)).toLocaleDateString()
            ) {
              return true;
            } else {
              return false;
            }
          } else if (!status && !search && date_end) {
            if (
              new Date(
                this.iosDate(this.filter_obj.date_end)
              ).toLocaleDateString() ===
              new Date(this.iosDate(x.date_create)).toLocaleDateString()
            ) {
              return true;
            } else {
              return false;
            }
          } else if (!status && search && !date_end) {
            if (x.phone.indexOf(this.filter_obj.search) !== -1) {
              return true;
            } else {
              return false;
            }
          } else if (status && !search && date_end) {
            if (
              Number(x.status_id) === Number(this.filter_obj.status_selected) &&
              new Date(
                this.iosDate(this.filter_obj.date_end)
              ).toLocaleDateString() ===
                new Date(this.iosDate(x.date_create)).toLocaleDateString()
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        })
        .sort((a, b) => {
          return (
            (new Date(b.date_create) > new Date(a.date_create)) -
            (new Date(a.date_create) > new Date(b.date_create))
          );
        });

      if (this.$store.state.user_setting.close_order) {
        return new_list.filter((x) => {
          if (x.status !== "Завершен") {
            if (x.status !== "Завершен неудачно") {
              return true;
            }
            return false;
          } else {
            return false;
          }
        });
      } else {
        return new_list;
      }
    },
  },
};
</script>

<style>
.slide_filter-enter {
  transform: translateX(-100%);
}

.slide_filter-enter-active {
  transition: 0.5s;
  will-change: transform;
}
.slide_filter-enter-to {
  transform: translateX(0);
}
.slide_filter-leave {
  transform: translateX(0);
}
.slide_filter-leave-active {
  transition: 0.5s;
  will-change: transform;
}

.slide_filter-leave-to {
  transform: translateX(-100%);
}

.slide_filter_hide-enter-active,
.slide_filter_hide-leave-active {
  transition: 0.5s;
  will-change: transform;
}
.slide_filter_hide-enter {
  transform: translateX(0px);
}
.slide_filter_hide-leave-to {
  transform: translateX(-100%);
}

.card_list-enter-active,
.card_list-leave-active {
  transition: 0.7s;
}
.card_list-enter,
.card_list-leave-to {
  opacity: 0;
}

.fade_down_detail-enter-active,
.fade_down_detail-leave-active {
  transition: 0.5s;
}
.fade_down_detail-enter {
  transform: translateX(-100%) scale(0);
}
.fade_down_detail-enter-to {
  transform: translateX(0px) scale(1);
}
.fade_down_detail-leave-to {
  transform: translateX(-100%) scale(0);
}

.fade_down-enter-active,
.fade_down-leave-active {
  transition: 0.5s;
  transform: translateY(0px);
}
.fade_down-enter, .fade_down-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  transform: translateY(100%);
}

.fade_up-enter {
  transform: translate(-100%, 100%) scale(0);
}

.fade_up-enter-active {
  transition: transform 0.5s;
}

.fade_up-enter-to {
  transform: translate(0%, 0%) scale(1);
}

.fade_up-leave {
  transition: transform 0.5s;
  transform: translateY(0px);
}
.fade_up-leave-active {
  transition: transform 0.5s;
  transform: translateY(0px);
}

.fade_up-leave-to {
  transform: translateY(100%);
}

.setting_fade-enter {
  transform: translateX(100%);
}

.setting_fade-enter-active {
  transition: 0.5s;
}

.setting_fade-enter-to {
  transform: translateX(0px);
}

.setting_fade-leave {
  transform: translateX(0px);
}
.setting_fade-leave-active {
  transition: transform 0.3s;
}

.setting_fade-leave-to {
  transform: translateX(100%);
}

.message_drop-enter {
  transform: translate(-25%, -25%) scaleX(0.2);
  transform-origin: top left;
}

.message_drop-enter-active {
  transition: 0.5s;
  transform-origin: top left;
}

.message_drop-enter-to {
  transform: translate(0, 0) scale(1);
  transform-origin: top left;
}

.message_drop-leave {
  transform: translate(0, 0) scaleX(1);
  transform-origin: top left;
}
.message_drop-leave-active {
  transition: transform 0.3s;
  transform-origin: top left;
}

.message_drop-leave-to {
  transform: translate(-10%, -10%) scale(0);
  transform-origin: top left;
}

.fade_down_detailmg-enter {
  transform: translate(-25%, -25%) scaleX(0.2);
  transform-origin: left;
}

.fade_down_detailmg-enter-active {
  transition: 0.5s;
  transform-origin: left;
}

.fade_down_detailmg-enter-to {
  transform: translate(0, 0) scale(1);
  transform-origin: left;
}

.fade_down_detailmg-leave {
  transform: translate(0, 0) scaleX(1);
  transform-origin: left;
}
.fade_down_detailmg-leave-active {
  transition: transform 0.3s;
  transform-origin: left;
}

.fade_down_detailmg-leave-to {
  transform: translate(-10%, -10%) scale(0);
  transform-origin: left;
}
.fade_down_history-enter-active,
.fade_down_history-leave-active {
  transition: 0.5s;
}
.fade_down_history-enter {
  transform: translateY(-100%) scale(0);
}
.fade_down_history-enter-to {
  transform: translateY(0px) scale(1);
}
.fade_down_history-leave-to {
  transform: translateY(-100%) scale(0);
}

.notice_right-enter-active,
.notice_right-leave-active {
  transition: 0.5s;
}
.notice_right-enter {
  transform: translateX(100%);
}
.notice_right-enter-to {
  transform: translateX(0px);
}
.notice_right-leave-to {
  transform: translateX(100%);
}
</style>
