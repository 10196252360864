<template>
  <div class="checkbox">
    <input
      type="number"
      v-model="selected_value"
      readonly
      @click.stop="show_list = !show_list"
    />
    <div class="menu__list" v-if="show_list" ref="dropdown">
      <button
        v-for="(item, index) in list"
        :key="index"
        @click="func_selected_value(item)"
        :class="{ cur: item == selected_value }"
      >
        {{ item }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    p_selected_value: Number,
  },
  data() {
    return {
      list: [
        5, 10, 15, 20, 25, 30, 50, 75, 100, 125, 150, 200, 250, 300, 400, 450,
        500, 600, 700, 800, 900, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500,
        5000, 5500, 6000, 6500, 7000, 7500, 8000, 8500, 9000, 9500, 10000,
        11000, 12000, 13000, 14000, 15000, 16000, 17000, 18000, 19000, 25000,
        30000, 40000, 50000, 60000, 70000, 80000,
      ].sort((a, b) => a - b),
      show_list: false,
      selected_value: 0,
    };
  },
  methods: {
    func_selected_value(val) {
      this.selected_value = val;
      this.show_list = false;
      this.$emit("update", this.selected_value);
    },
    dropdown(e) {
      let el = this.$refs.dropdown;
      if (!el) return;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        this.show_list = false;
        this.$emit("update", this.selected_value);
      }
    },
  },
  mounted() {
    this.selected_value = this.p_selected_value;
  },
  created() {
    document.addEventListener("click", this.dropdown);
  },
  destroyed() {
    document.removeEventListener("click", this.dropdown);
  },
};
</script>

<style scoped>

</style>

