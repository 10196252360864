<template>
  <div class="detail__popup__wrapper">
    <DetailModal
      @cancel="func_detail_cancel"
      @save="func_detail_save"
      v-if="modal_detail_show"
      :option="modal_detail_option"
    />
    <div class="details__popup">
      <div id="print2" v-show="false" v-html="print"></div>
      <div class="popup__flex">
        <div class="left__block">
          <div class="status-det">
            <div
              class="detaltab-stat"
              :style="{ background: func_get_status_one().color }"
            >
              {{ func_get_status_one().status }}
            </div>
          </div>

          <div class="detailtab">
            <div class="detailtab-b">
              <div class="detailtab-r">
                <div class="detailtab-c">Заказ</div>
                <div class="detailtab-c">{{ order.order_id }}</div>
              </div>

              <div class="detailtab-r">
                <div class="detailtab-c">Дата</div>
                <div class="detailtab-c">
                  {{
                    new Date(
                      iosDate(order.create_datetime)
                    ).toLocaleDateString() +
                    " " +
                    new Date(
                      iosDate(order.create_datetime)
                    ).toLocaleTimeString()
                  }}
                </div>
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Файл</div>
                <div class="detailtab-c">
                  <a
                    style="cursor: pointer"
                    :href="'https://app.pcbchina.ru/files/' + order.file"
                    >{{ order.file }}</a
                  >
                </div>
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Стоимость плат:</div>
                <div class="detailtab-c">{{ order.price }}</div>
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Доставка</div>
                <div class="detailtab-c">{{ order.tipdost }}</div>
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Номер отправления:</div>
                <div class="detailtab-c">{{ order.num_departure }}</div>
              </div>
            </div>
          </div>
          <!--
          <div class="imgdetail-all">
            <img
              :src="
                order.img
                  ? 'https://adm.pcbchina.ru/img/upload/' + order.img
                  : require('@/assets/pcb.png')
              "
              alt=""
              class="imgdetail"
            />
          </div>-->
        </div>
        <div class="right__block">
          <div class="detailtab">
            <div class="detailtab-b" v-if="order.mak == 'Один макет PCB'">
              <div class="detailtab-r">
                <div class="detailtab-c">Размер</div>
                <div class="detailtab-c">
                  {{ order.razx }} x {{ order.razy }}
                </div>
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Слои</div>
                <div class="detailtab-c">{{ order.sloi }}</div>
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Материал</div>
                <div class="detailtab-c">{{ order.material }}</div>
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Количество</div>
                <div class="detailtab-c">{{ order.counter }}</div>
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Толщина платы</div>
                <div class="detailtab-c">{{ order.bold }}</div>
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Толщина дорожки</div>
                <div class="detailtab-c">{{ order.level }}</div>
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Мин. отверстие</div>
                <div class="detailtab-c">{{ order.min_shere }}</div>
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Медь</div>
                <div class="detailtab-c">{{ order.med }}</div>
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Цвет</div>
                <div class="detailtab-c btn-det">
                  <p>
                    <i :style="{ background: func_get_color() }"></i
                    ><span class="ver">{{ order.color }}</span>
                  </p>
                </div>
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Финишное покрытие</div>
                <div class="detailtab-c">{{ order.finish }}</div>
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Удалить номер заказа</div>
                <div class="detailtab-c">{{ order.noorder }}</div>
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Панелирование</div>
                <div class="detailtab-c">
                  {{ order.panelx }} x {{ order.panely }}
                </div>
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Другой дизайн</div>
                <div class="detailtab-c">{{ order.diz }}</div>
              </div>

              <div class="detailtab-r">
                <div class="detailtab-c">Трафарет</div>
                <div class="detailtab-c">{{ order.trafaret }}  {{ order.trafaretsize }} {{ order.trafaretfreim }} {{ order.trafaretpol }}</div>
              </div>
            </div>

            <div
              class="detailtab-b det-opt"
              v-if="order.mak == 'Оптовый заказ PCB'"
            >
              <div class="detailtab-r">
                Оптовый заказ, мы применили настройки которые вы указали в
                файле, если у нас будут вопросы, мы свяжемся с вами.
              </div>
            </div>

            <div class="detailtab-b det-dr" v-if="order.mak == 'Другой заказ'">
              <div class="detailtab-r" style="display: table">
                Выкуп на вашем аккаунте
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Ресурс</div>
                <div class="detailtab-c">
                  <input
                    type="text"
                    autocomplete="off"
                    class="inp-det"
                    name="purchaseNumber"
                    property="1"
                    v-model="order.service"
                  />
                </div>
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Логин</div>
                <div class="detailtab-c">
                  <input
                    type="text"
                    autocomplete="off"
                    class="inp-det"
                    name="purchaseNumber"
                    property="1"
                    v-model="order.loginps"
                  />
                </div>
              </div>
              <div class="detailtab-r">
                <div class="detailtab-c">Пароль</div>
                <div class="detailtab-c">
                  <input
                    type="text"
                    autocomplete="off"
                    class="inp-det"
                    name="purchaseNumber"
                    property="1"
                    v-model="order.password"
                  />
                </div>
              </div>
              
              

            

            <div class="detailtab-b det-dr" v-if="order.mak == 'Другой заказ'">
            <div class="text-dop-set">
             Укажите трек номер отправления по Китаю, обычно он начинается на SF
            </div>
<div class="detailtab-r">
                
                <div class="detailtab-c">
                  <input
                    type="text"
                    autocomplete="off"
                    class="inp-det"
                    name="purchaseNumber"
                    property="1"
                    v-model="order.tracksf"
                  />
                </div>
              </div></div>
            
<div class="text-dop-set" @click="func_save_more()">
                <div
                  class="det-btn"
                  @click="func_save_more()"
                  :style="{ border: save_more ? '1px solid green' : 'none' }"
                >
                  {{ save_more ? "Сохранено" : "Сохранить" }}
                </div>
              </div>
            </div>
<div class="text-dop-set">
              Дополнительные настройки: {{ order.setting_info }}
            </div>
          </div>

          <div class="header__back_btn">
            <div @click="func_close_detail()" class="close_detals">
              <i aria-hidden="true" class="fa fa-close"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    order: Object,
    update_detail: {
      type: Boolean,
      required: false,
    },
  },

  components: {},
  data() {
    return {
      modal_detail_option: null,
      history_show: false,
      data: {},
      status: null,
      status_arr: [],
      device_arr: [],
      group_arr: [],
      masters_arr: [],
      product_list: [],
      print: null,
      modal_detail_show: false,
      save_more: false,
    };
  },
  watch: {
    update_detail(val) {
      if (val) this.func_get_product_list();
    },
  },
  methods: {
    func_save_more() {
      this.$sendRequest("order.php/save_order_more", {
        order_id: this.order.order_id,
        loginps: this.order.loginps,
        password: this.order.password,
         tracksf: this.order.tracksf,
        service: this.order.service,
      }).then(() => {
        this.save_more = true;
      });
    },
    func_get_status() {
      this.$sendRequest("order.php/get_status_new", {}).then((result) => {
        if (!result) return;
        this.status = result;
      });
    },
    func_get_status_one() {
      if (this.status) {
        return this.status.find(
          (x) => Number(x.status_id) == Number(this.order.status_id)
        );
      } else {
        return {};
      }
    },
    func_get_date_end() {
      let date_end_str =
        this.data && this.data.date_end
          ? new Date(this.data.date_end).toLocaleDateString()
          : "";
      let date_end =
        this.data && this.data.date_end ? new Date(this.data.date_end) : null;
      let date_now = new Date();
      let diffInDays = 0;
      if (date_end) {
        let oneDay = 1000 * 60 * 60 * 24;
        let diffInTime = date_end.getTime() - date_now.getTime();
        diffInDays = Math.round(diffInTime / oneDay);
      }
      let color = "";

      if (diffInDays > 1) {
        color = "#328d30";
      } else if (diffInDays === 1) {
        color = "#dd8b37";
      } else {
        color = "#c74545";
      }
      let status =
        this.status_arr.length > 0
          ? this.status_arr.find(
              (x) => Number(x.id) === Number(this.data.status_id)
            ).value
          : "";
      return status === "Завершен" || status === "Завершен неудачно"
        ? `к ${date_end_str}`
        : `к ${date_end_str} (<span style="color:${color}">${
            diffInDays + " " + this.func_get_word(diffInDays)
          }</span>)`;
    },
    func_get_word(n) {
      n = Math.abs(n) % 100;
      var n1 = n % 10;
      if (n > 10 && n < 20) {
        return "дней";
      }
      if (n1 > 1 && n1 < 5) {
        return "дня";
      }
      if (n1 == 1) {
        return "день";
      }
      return "дней";
    },
    func_show_setting() {
      this.$router.push({ name: "setting", params: { id: 4 } });
    },
    iosDate(date) {
      return date ? date.replace(" ", "T") : "";
    },
    func_edit_stop() {
      let status = this.status_arr.find(
        (x) => Number(x.id) === Number(this.data.status_id)
      );
      if (status) {
        if (
          status.value === "Завершен" ||
          status.value === "Завершен неудачно"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    func_update_master() {
      this.func_save();
    },
    func_update_status(obj) {
      if (obj.value === "Завершен" || obj.value === "Завершен неудачно") {
        this.modal_detail_show = true;
        this.modal_detail_option = obj;
      } else {
        this.data.status_id = obj.id;
        this.func_save();
      }
    },
    func_add_product() {
      if (!this.func_edit_stop()) {
        this.$emit("show_order", {
          order_id: this.order_id,
          order_num: this.data.order_num,
        });
      }
    },
    func_get_data() {
      if (this.order_id && Number(this.order_id)) {
        let url = "order.php/get_detail";
        let param = {
          order_id: this.order_id,
        };
        this.$sendRequest(url, param).then((result) => {
          if (result) {
            if (result.details) this.data = result.details;
            if (result.status) this.status_arr = result.status;
            if (result.device) this.device_arr = result.device;
            if (result.groups) this.group_arr = result.groups;
            if (result.masters) this.masters_arr = result.masters;
          }
        });
      }
    },
    func_update_fire() {
      if (Number(this.data.fire) === 1) {
        this.data.fire = 0;
      } else {
        this.data.fire = 1;
      }
      this.func_save();
    },
    func_save(status = false) {
      if (status === false && this.func_edit_stop()) return;
      let url = "order.php/save_detail";
      let param = this.data;
      if (param) {
        this.$sendRequest(url, param).then((result) => {
          let user_state = { ...this.USER_STATE };
          user_state.amount_card = result.amount_card;
          user_state.amount_nal = result.amount_nal;
          this.$store.commit("CHANGE_USER", user_state);
          this.func_get_obj_data();
        });
      }
    },
    func_get_summ() {
      let res = 0;

      if (this.product_list.length) {
        this.product_list.forEach(function (item) {
          res += Number(item.price);
        });
      }
      return res;
    },
    func_get_obj_data() {
      let status = this.status_arr.find(
        (x) => Number(x.id) === Number(this.data.status_id)
      );
      let device = this.device_arr.find(
        (x) => Number(x.id) === Number(this.data.device_id)
      );
      this.$emit("change_data", {
        status,
        device,
        order_id: this.data.order_id,
        fire: this.data.fire,
        group_name: this.group_arr.find((x) => x.id === this.data.group_id)
          ? this.group_arr.find((x) => x.id === this.data.group_id).value
          : "",
      });
    },
    func_close_detail() {
      this.$emit("close_detail");
    },
    func_send_sms() {
      this.$iosConfirm("Отправить СМС?").then(() => {
        let url = "order.php/send_sms";
        let param = {
          order_id: this.order_id,
        };
        this.$sendRequest(url, param).then((result) => {
          if (result.success) this.data.sms_send = 1;
        });
      });
    },
    func_get_print(type) {
      this.print = null;
      let url = "order.php/get_print";
      this.$sendRequest(url, {
        type,
        order_id: this.order_id,
      }).then((result) => {
        if (!result) return;
        this.print = result;
        setTimeout(() => {
          this.$htmlToPaper("print2");
        }, 1000);
      });
    },
    func_get_product_list() {
      let url = "order.php/get_products_detail";
      this.$sendRequest(url, {
        order_id: this.order_id,
      }).then((result) => {
        if (!result) return;
        this.product_list = result;
      });
    },
    func_detail_cancel(option) {
      let status_old = option.item.status_id.toString();
      let status_new = option.id.toString();

      this.data.status_id = status_new;
      setTimeout(() => {
        this.data.status_id = status_old;
      }, 200);

      this.modal_detail_show = false;
    },
    func_detail_save(option) {
      this.data.status_id = option.id.toString();
      this.modal_detail_show = false;
      this.data.amount_id = option.type_amount;

      if (option.comment) this.data.comment1 = option.comment;
      if (option.itog) this.data.itog = option.itog;

      this.func_save(true);
    },

    func_get_color() {
      let color = this.order.color || "";
      let color_out = "";
      switch (color) {
        case "Зеленый":
          color_out = "green";
          break;
        case "Фиолетовый":
          color_out = "";
          break;
        case "Красный":
          color_out = "red";
          break;
        case "Желтый":
          color_out = "yellow";
          break;
        case "Синий":
          color_out = "blue";
          break;
        case "Белый":
          color_out = "white";
          break;
        case "Черный":
          color_out = "black";
          break;
      }
      return color_out;
    },
  },
  mounted() {
    this.func_get_status();
    // this.func_get_product_list();
  },
  computed: {
    ...mapGetters(["FIELDS_STATE"]),
    ...mapGetters(["USER_STATE"]),
    customer_name: {
      get() {
        return "ID " + this.data.customer_num + " | " + this.data.customer;
      },
    },
  },
};
</script>

<style></style>
