<template>
  <div class="detail__popup__wrapper">
    <DetailmgModal
      @cancel="func_detailmg_cancel"
      @save="func_detailmg_save"
      v-if="modal_detailmg_show"
      :option="modal_detailmg_option"
    />
    <div class="details__popup2">
      <div id="print2" v-show="false" v-html="print"></div>
      <div class="right__block">
        <div class="close_detals2">
          <div @click="func_close_detailmg()" class="arrow-7">
            <i aria-hidden="true" class="fa fa-close"></i>
          </div>
        </div>
      </div>

      <div class="imgdetail-all">
        <img
          :src="
            order.status_id == 5
              ? require('@/assets/fot.jpg')
              : order.img
              ? 'https://adm.pcbchina.ru/img/upload/' + order.img
              : require('@/assets/pcb.png')
          "
          alt=""
          class="imgdetail2"
        />
      </div>

      <div class="popup__flex"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    order: Object,
    update_detailmg: {
      type: Boolean,
      required: false,
    },
  },

  components: {},
  data() {
    return {
      modal_detailmg_option: null,
      history_show: false,
      data: {},
      status: null,
      status_arr: [],
      device_arr: [],
      group_arr: [],
      masters_arr: [],
      product_list: [],
      print: null,
      modal_detailmg_show: false,
    };
  },
  watch: {
    update_detailmg(val) {
      if (val) this.func_get_product_list();
    },
  },
  methods: {
    func_get_status() {
      this.$sendRequest("order.php/get_status_new", {}).then((result) => {
        if (!result) return;
        this.status = result;
      });
    },
    func_get_status_one() {
      if (this.status) {
        return this.status.find(
          (x) => Number(x.status_id) == Number(this.order.status_id)
        );
      } else {
        return {};
      }
    },
    func_get_date_end() {
      let date_end_str =
        this.data && this.data.date_end
          ? new Date(this.data.date_end).toLocaleDateString()
          : "";
      let date_end =
        this.data && this.data.date_end ? new Date(this.data.date_end) : null;
      let date_now = new Date();
      let diffInDays = 0;
      if (date_end) {
        let oneDay = 1000 * 60 * 60 * 24;
        let diffInTime = date_end.getTime() - date_now.getTime();
        diffInDays = Math.round(diffInTime / oneDay);
      }
      let color = "";

      if (diffInDays > 1) {
        color = "#328d30";
      } else if (diffInDays === 1) {
        color = "#dd8b37";
      } else {
        color = "#c74545";
      }
      let status =
        this.status_arr.length > 0
          ? this.status_arr.find(
              (x) => Number(x.id) === Number(this.data.status_id)
            ).value
          : "";
      return status === "Завершен" || status === "Завершен неудачно"
        ? `к ${date_end_str}`
        : `к ${date_end_str} (<span style="color:${color}">${
            diffInDays + " " + this.func_get_word(diffInDays)
          }</span>)`;
    },
    func_get_word(n) {
      n = Math.abs(n) % 100;
      var n1 = n % 10;
      if (n > 10 && n < 20) {
        return "дней";
      }
      if (n1 > 1 && n1 < 5) {
        return "дня";
      }
      if (n1 == 1) {
        return "день";
      }
      return "дней";
    },
    func_show_setting() {
      this.$router.push({ name: "setting", params: { id: 4 } });
    },
    iosDate(date) {
      return date ? date.replace(" ", "T") : "";
    },
    func_edit_stop() {
      let status = this.status_arr.find(
        (x) => Number(x.id) === Number(this.data.status_id)
      );
      if (status) {
        if (
          status.value === "Завершен" ||
          status.value === "Завершен неудачно"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    func_update_master() {
      this.func_save();
    },
    func_update_status(obj) {
      if (obj.value === "Завершен" || obj.value === "Завершен неудачно") {
        this.modal_detailmg_show = true;
        this.modal_detailmg_option = obj;
      } else {
        this.data.status_id = obj.id;
        this.func_save();
      }
    },
    func_add_product() {
      if (!this.func_edit_stop()) {
        this.$emit("show_order", {
          order_id: this.order_id,
          order_num: this.data.order_num,
        });
      }
    },
    func_get_data() {
      if (this.order_id && Number(this.order_id)) {
        let url = "order.php/get_detail";
        let param = {
          order_id: this.order_id,
        };
        this.$sendRequest(url, param).then((result) => {
          if (result) {
            if (result.details) this.data = result.details;
            if (result.status) this.status_arr = result.status;
            if (result.device) this.device_arr = result.device;
            if (result.groups) this.group_arr = result.groups;
            if (result.masters) this.masters_arr = result.masters;
          }
        });
      }
    },
    func_update_fire() {
      if (Number(this.data.fire) === 1) {
        this.data.fire = 0;
      } else {
        this.data.fire = 1;
      }
      this.func_save();
    },
    func_save(status = false) {
      if (status === false && this.func_edit_stop()) return;
      let url = "order.php/save_detail";
      let param = this.data;
      if (param) {
        this.$sendRequest(url, param).then((result) => {
          let user_state = { ...this.USER_STATE };
          user_state.amount_card = result.amount_card;
          user_state.amount_nal = result.amount_nal;
          this.$store.commit("CHANGE_USER", user_state);
          this.func_get_obj_data();
        });
      }
    },
    func_get_summ() {
      let res = 0;

      if (this.product_list.length) {
        this.product_list.forEach(function (item) {
          res += Number(item.price);
        });
      }
      return res;
    },
    func_get_obj_data() {
      let status = this.status_arr.find(
        (x) => Number(x.id) === Number(this.data.status_id)
      );
      let device = this.device_arr.find(
        (x) => Number(x.id) === Number(this.data.device_id)
      );
      this.$emit("change_data", {
        status,
        device,
        order_id: this.data.order_id,
        fire: this.data.fire,
        group_name: this.group_arr.find((x) => x.id === this.data.group_id)
          ? this.group_arr.find((x) => x.id === this.data.group_id).value
          : "",
      });
    },
    func_close_detailmg() {
      this.$emit("close_detailmg");
    },
    func_send_sms() {
      this.$iosConfirm("Отправить СМС?").then(() => {
        let url = "order.php/send_sms";
        let param = {
          order_id: this.order_id,
        };
        this.$sendRequest(url, param).then((result) => {
          if (result.success) this.data.sms_send = 1;
        });
      });
    },
    func_get_print(type) {
      this.print = null;
      let url = "order.php/get_print";
      this.$sendRequest(url, {
        type,
        order_id: this.order_id,
      }).then((result) => {
        if (!result) return;
        this.print = result;
        setTimeout(() => {
          this.$htmlToPaper("print2");
        }, 1000);
      });
    },
    func_get_product_list() {
      let url = "order.php/get_products_detail";
      this.$sendRequest(url, {
        order_id: this.order_id,
      }).then((result) => {
        if (!result) return;
        this.product_list = result;
      });
    },
    func_detailmg_cancel(option) {
      let status_old = option.item.status_id.toString();
      let status_new = option.id.toString();

      this.data.status_id = status_new;
      setTimeout(() => {
        this.data.status_id = status_old;
      }, 200);

      this.modal_detailmg_show = false;
    },
    func_detailmg_save(option) {
      this.data.status_id = option.id.toString();
      this.modal_detailmg_show = false;
      this.data.amount_id = option.type_amount;

      if (option.comment) this.data.comment1 = option.comment;
      if (option.itog) this.data.itog = option.itog;

      this.func_save(true);
    },

    func_get_color() {
      let color = this.order.color || "";
      let color_out = "";
      switch (color) {
        case "Зеленый":
          color_out = "green";
          break;
        case "Фиолетовый":
          color_out = "";
          break;
        case "Красный":
          color_out = "red";
          break;
        case "Желтый":
          color_out = "yellow";
          break;
        case "Синий":
          color_out = "blue";
          break;
        case "Белый":
          color_out = "white";
          break;
        case "Черный":
          color_out = "black";
          break;
      }
      return color_out;
    },
  },
  mounted() {
    this.func_get_status();
    // this.func_get_product_list();
  },
  computed: {
    ...mapGetters(["FIELDS_STATE"]),
    ...mapGetters(["USER_STATE"]),
    customer_name: {
      get() {
        return "ID " + this.data.customer_num + " | " + this.data.customer;
      },
    },
  },
};
</script>

<style></style>
