<template>
  <div class="history__wrap">
    <div class="modal-dialog w700" ng-class="modalWidth" role="document">
      <div class="modal-content">
        <div ng-transclude="">
          <div
            order-proce=""
            list="form.item"
            item="form.itemActive"
            class="ng-scope ng-isolate-scope"
          >
            <div class="detail-main pl40">
              <div
                class="modal-header borderBNone"
                style="padding: 10px 10px 5px 0"
              >
                <button
                  type="button"
                  class="close opacity1"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img
                    src="images/pop_close.svg"
                    aria-hidden="true"
                    @click="$emit('close_history')"
                  />
                </button>
              </div>
              <p class="font20 color2">Процесс</p>
              <p
                class="over font14 ng-binding ng-scope"
                ng-if="item.estimateAchieveDate"
                v-if="order.status_id == 4 || order.status_id == 5"
              >
                Ожидание вашего заказа составит в среднем 20-40 дней
              </p>
              <div class="over flex align-center">
                <div class="processOutBox inline">
                  <div
                    class="processWidth"
                    id="lineProcess"
                    :style="{ width: func_get_progress() + '%' }"
                  ></div>
                </div>
                <div class="inline ml10 font20 blue strong" id="lineProcessTxt">
                  {{ func_get_progress() }}%
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12">
                  <div class="track-list clearfix">
                    <ul class="processUl pl20">
                      <li
                        ng-repeat="item in list"
                        class="row paddingB10 ng-scope process-border"
                        ng-class="{'process-border':$index<list.length-1}"
                        v-for="(item, index) in status_list"
                        :key="item.status_id"
                      >
                        <div class="pcbProcessLiTop">
                          <span
                            class="process-icon ng-binding ng-scope"
                            ng-if="(!item.endTime) &amp;&amp; (!item.beginTime)"
                            v-if="item.show_num"
                            >{{ index + 1 }}</span
                          >
                          <span
                            ng-if="item.endTime &amp;&amp;$index>=0"
                            class="completeIcon ng-scope"
                            v-else
                          >
                            <img :src="item.img_1" alt="" />
                          </span>
                          <p class="txt font14 pl0 pr0">
                            <span
                              ng-class="{'color9':!item.endTime}"
                              class="ng-binding"
                              :class="{ color9: item.show_num }"
                              >{{ item.text }}
                            </span>
                          </p>
                          <p
                            class="time font15 col-xs-5 w38p ng-binding"
                            v-html="item.description"
                            v-if="func_show_description(item)"
                          ></p>
                        </div>
                        <div
                          class="over relative ng-hide"
                          ng-show="item.videoShow"
                        >
                          <img
                            :src="item.img_2"
                            class="process-video-close hand"
                            ng-click="hideModal(item)"
                            alt=""
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status_list: [
        {
          status_id: 4,
          show_num: false,
          text: "Обработка",
          img_1: "images/now_icon.svg",
          img_2: "images/after-sale-closed.svg",
          description: "Мы проверяем ваш заказ (5-120 минут)",
        },
        {
          status_id: 5,
          show_num: false,
          text: "Оплачен",
          img_1: "images/now_icon.svg",
          img_2: "images/after-sale-closed.svg",
          description: "Заказ подтвержден. Ожидаем оплату",
        },
        {
          status_id: 7,
          show_num: false,
          text: "Производство",
          img_1: "images/now_icon.svg",
          img_2: "images/after-sale-closed.svg",
          description: "Производство занимает от 2-10 дней",
        },
        {
          status_id: 8,
          show_num: false,
          text: "Отправлен на склад",
          img_1: "images/now_icon.svg",
          img_2: "images/after-sale-closed.svg",
          description: "Доставка по Китаю (1-5 дней)",
        },
        {
          status_id: 9,
          show_num: false,
          text: "На складе (Китай)",
          img_1: "images/now_icon.svg",
          img_2: "images/after-sale-closed.svg",
          description:
            "Фото-отчет TG <a href='https://t.me/pcbchina1' target='_blank'>@pcbchina1</a>|<a href='https://vk.com/pcbchina' target='_blank'>vk.com/pcbchina</a>",
        },
        {
          status_id: 10,
          show_num: false,
          text: "Отправлен в РФ",
          img_1: "images/now_icon.svg",
          img_2: "images/after-sale-closed.svg",
          description: "Средний срок 5-15 дней",
        },
        {
          status_id: 11,
          show_num: false,
          text: "На складе (Россия)",
          img_1: "images/now_icon.svg",
          img_2: "images/after-sale-closed.svg",
          description: "Формирование посылки 0-2 дня",
        },
        {
          status_id: 12,
          show_num: false,
          text: "Отправлен по РФ",
          img_1: "images/now_icon.svg",
          img_2: "images/after-sale-closed.svg",
          description: "Трек номер: " + this.track,
        },
        {
          status_id: 3,
          show_num: false,
          text: "Завершен",
          img_1: "images/now_icon.svg",
          img_2: "images/after-sale-closed.svg",
          description: "",
        },
      ],
    };
  },
  props: {
    order: Object,
    track: { type: String, default: "" },
  },
  methods: {
    func_get_svg() {
      let status_id = Number(this.order.status_id);
      let result_close = "images/after_icon.svg",
        result_now = "images/now_icon.svg";
      this.status_list.forEach((item) => {
        let st_id = item.status_id;
        if (status_id == 4) {
          if (st_id == 4) {
            item.show_num = false;
            item.img_1 = result_now;
          } else {
            item.show_num = true;
          }
        } else if (status_id == 5 || status_id == 6) {
          if (status_id == 5) {
            if (st_id == 5) {
              item.show_num = false;
              item.img_1 = result_now;
            } else if (st_id == 4) {
              item.show_num = false;
              item.img_1 = result_close;
            } else {
              item.show_num = true;
            }
          } else {
            if (st_id == 5) {
              item.show_num = false;
              item.img_1 = result_close;
            } else if (st_id == 4) {
              item.show_num = false;
              item.img_1 = result_close;
            } else {
              item.show_num = true;
            }
          }
        } else if (status_id == 7) {
          if (st_id == 7) {
            item.show_num = false;
            item.img_1 = result_now;
          } else if (st_id == 4 || st_id == 5) {
            item.show_num = false;
            item.img_1 = result_close;
          } else {
            item.show_num = true;
          }
        } else if (status_id == 8) {
          if (st_id == 8) {
            item.show_num = false;
            item.img_1 = result_now;
          } else if (st_id == 4 || st_id == 5 || st_id == 7) {
            item.show_num = false;
            item.img_1 = result_close;
          } else {
            item.show_num = true;
          }
        } else if (status_id == 9) {
          if (st_id == 9) {
            item.show_num = false;
            item.img_1 = result_now;
          } else if (st_id == 4 || st_id == 5 || st_id == 7 || st_id == 8) {
            item.show_num = false;
            item.img_1 = result_close;
          } else {
            item.show_num = true;
          }
        } else if (status_id == 10) {
          if (st_id == 10) {
            item.show_num = false;
            item.img_1 = result_now;
          } else if (
            st_id == 4 ||
            st_id == 5 ||
            st_id == 7 ||
            st_id == 8 ||
            st_id == 9
          ) {
            item.show_num = false;
            item.img_1 = result_close;
          } else {
            item.show_num = true;
          }
        } else if (status_id == 11) {
          if (st_id == 11) {
            item.show_num = false;
            item.img_1 = result_now;
          } else if (
            st_id == 4 ||
            st_id == 5 ||
            st_id == 7 ||
            st_id == 8 ||
            st_id == 9 ||
            st_id == 10
          ) {
            item.show_num = false;
            item.img_1 = result_close;
          } else {
            item.show_num = true;
          }
        } else if (status_id == 12) {
          if (st_id == 12) {
            item.show_num = false;
            item.img_1 = result_now;
          } else if (
            st_id == 4 ||
            st_id == 5 ||
            st_id == 7 ||
            st_id == 8 ||
            st_id == 9 ||
            st_id == 10 ||
            st_id == 11
          ) {
            item.show_num = false;
            item.img_1 = result_close;
          } else {
            item.show_num = true;
          }
        } else if (status_id == 3) {
          item.show_num = false;
          item.img_1 = result_close;
        } else {
          item.show_num = true;
          item.img_1 = result_close;
        }
      });
    },
    func_show_description(obj) {
      let result = false;
      let status_id = Number(this.order.status_id);
      if (status_id == obj.status_id) {
        result = true;
      } else {
        if (obj.status_id == 12 && (status_id == 12 || status_id == 3)) {
          result = true;
        } else if (
          obj.status_id == 9 &&
          (status_id == 9 ||
            status_id == 10 ||
            status_id == 11 ||
            status_id == 12 ||
            status_id == 3)
        ) {
          result = true;
        } else {
          result = false;
        }
      }
      return result;
    },
    func_get_progress() {
      let status_id = Number(this.order.status_id);
      let result = 0;
      switch (status_id) {
        case 4:
          result = 0;
          break;
        case 5:
        case 6:
          result = 5;
          break;
        case 7:
          result = 17;
          break;
        case 8:
          result = 45;
          break;
        case 9:
          result = 54;
          break;
        case 10:
          result = 64;
          break;
        case 11:
          result = 89;
          break;
        case 12:
          result = 95;
          break;
        case 3:
          result = 100;
          break;
      }
      return result;
    },
  },
  mounted() {
    this.func_get_svg();
  },
};
</script>

<style scoped src="@/css/history.css">
</style>