<template>
  <div
    class="order__card"
    v-bind:style="{ border: '1px solid ' + obj.status_color }"
  >
    <Notice v-if="notice.show" :text="notice.text" @close="func_close_notice" />
    <div class="divTable">
      <div class="divTableBody">
        <div class="divTableRow">
          <div class="divTableCell1">
            <div class="date-add-o">
              {{
                new Date(obj.create_datetime).toLocaleDateString() +
                " " +
                new Date(obj.create_datetime).toLocaleTimeString()
              }}
            </div>

            <div class="order-num-cart">
              {{ obj.order_id }}
            </div>

            <div class="imgblk" type="button">
              <img
                @click="$emit('show_detailmg')"
                :src="
                  obj.status_id == 5
                    ? require('@/assets/fot.jpg')
                    : obj.img
                    ? 'https://adm.pcbchina.ru/img/upload/' + obj.img
                    : require('@/assets/pcb.png')
                "
                alt=""
                class="imgmain"
              />
              <div class="imgblk2" v-if="obj.status_id != 5 && !obj.img">
                Обработка
                <div
                  class="loader-time"
                  style="--b: 3px; --c: #212121cf; width: 23px; --n: 20"
                ></div>
                <div class="time-bl">5-120 мин</div>
              </div>

              <div class="price-blk" v-if="obj.status_id != 4">
                {{ obj.price }} руб
              </div>
              <div class="diliver" v-if="obj.status_id == 4">
                Доставка бесплатно
              </div>
              <div class="obrord" v-if="obj.status_id == 4">
                Когда заказ будет обработан, мы пришлем письмо на ваш почтовый
                ящик,
                <a class="sp-e"> письмо может попасть в папку спам. </a>
              </div>
 <div  v-if="obj.mak == 'Другой заказ'">
              <div class="obrord-sf" v-if="obj.status_id == 7">
               Когда заказ будет готов, нажмите "подробнее" и введите трек номер отправления по китаю
                <a class="sp-e"> обычно он начинается на SF </a>
                
              </div> </div>
              <div class="pcs" v-if="obj.mak == 'Один макет PCB'">
                {{ obj.counter }} шт
              </div>

              <div class="gerberdown" v-if="obj.mak == 'Один макет PCB'">
                <a
                  style="cursor: pointer"
                  :href="'https://app.pcbchina.ru/files/' + obj.file"
                >
                  <i class="fa fa-download" aria-hidden="true"></i>
                  Ваш Gerber</a
                >
              </div>
              <div
                class="gerberup"
                v-if="obj.zamena == 'Требуется'"
                @click="func_update_gerber()"
              >
                <a>
                  <i class="fa fa-upload" aria-hidden="true"></i>
                  Заменить файл</a
                >
              </div>
              <span v-if="obj.admin">
                <div class="adm-com">
                  {{ obj.admin }}
                </div></span
              >
            </div>
          </div>
          <div class="divTableCell2 ditaleorder text-ord">
            <br />
          </div>
          <div class="divTableCell3">
            <div
              class="btn-pay-stat"
              :style="{ background: func_get_status_one().color }"
            >
              {{ func_get_status_one().status }}
            </div>
            <div class="btn-pay" v-if="obj.status_id == 5" @click="func_pay">
              {{ obj.doplata ? "Доплатить" : "Оплатить" }}
            </div>

            <div class="btn-dopl" v-if="obj.doplata">{{ obj.doplata }}</div>
            <div class="btn-pay-ok" v-if="Number(obj.payment)">
              <i aria-hidden="true" class="fa fa-check"></i>
            </div>
            <div class="btn-pay-que" v-if="Number(obj.tex)">
              <i aria-hidden="true" class="fa fa-question-circle-o"></i>
              Технический вопрос, проверьте почтовый ящик
            </div>
            <!--  <div class="track-tx"> Заказ отправлен</div> -->
            <div class="track-id" v-if="obj.num_departure">
              {{ "Номер отправления: " + obj.num_departure }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <input
      type="file"
      id="file"
      :ref="'file' + obj.order_id"
      v-on:change="func_file_upload()"
      style="display: none"
      accept=".zip"
    />
    <div
      class="btn-ord"
      type="button"
      @click="$emit('show_detail')"
      tooltip-position="bottom left"
    >
      Подробнее
    </div>
    <div
      class="btn-proc"
      type="button"
      @click="$emit('show_history')"
      tooltip-position="bottom left"
    >
      Процесс
    </div>
    <div
      class="btn-ord-del tooltip"
      type="button"
      @click="func_remove_order"
      tooltip="Удалить"
      tooltip-position="bottom left"
      v-if="obj.status_id == 4 || obj.status_id == 5 || obj.status_id == 13"
    >
      <i aria-hidden="true" class="fa fa-trash-o"></i>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Notice from "@/components/notification.vue";

export default {
  components: { Notice },
  props: {
    obj: Object,
  },
  data() {
    return {
      status: null,
      pay: false,
      file_zamena: "",
      notice: {
        show: false,
        text: "",
      },
      text_notice: "fggfgfgffggf",
    };
  },
  methods: {
    func_close_notice() {
      this.notice = {
        show: false,
        text: "",
      };
    },
    func_update_gerber() {
      let html_obj = this.$refs["file" + this.obj.order_id];
      html_obj.setAttribute("type", "text");
      html_obj.setAttribute("type", "file");
      html_obj.click();
    },
    func_file_upload() {
      this.file_zamena = this.$refs["file" + this.obj.order_id].files[0];
      if (this.file_zamena.size / 1024 / 1024 > 10) {
        this.file_zamena = "";
        this.notice = {
          show: true,
          text: "Файл больше 10 мб",
        };
        return;
      }
      if (!this.file_zamena) {
        this.file_zamena = "";
        this.notice = {
          show: true,
          text: "Выберите файл",
        };
        return;
      }
      this.$sendRequest("order.php/zamena_file", {
        file: this.file_zamena,
        order_id: this.obj.order_id,
        path: "files",
      }).then((result) => {
        this.$store.commit("CHANGE_NOTICE_MSG", result.msg);
        if (result.success) {
          this.obj.zamena = "Не требуется";
          this.obj.file = result.file;
        }
      });
    },
    func_get_status_one() {
      if (this.status) {
        return this.status.find(
          (x) => Number(x.status_id) == Number(this.obj.status_id)
        );
      } else {
        return {};
      }
    },
    func_remove_order() {
      this.$iosConfirm("Удалить?").then(() => {
        let url = "order.php/remove_order_new";
        this.$sendRequest(url, { order_id: this.obj.order_id }).then(() => {
          this.$emit("order_del", this.obj.order_id);
        });
      });
    },
    func_get_status() {
      this.$sendRequest("order.php/get_status_new", {}).then((result) => {
        if (!result) return;
        this.status = result;
      });
    },
    func_pay() {
      this.$emit("pay", this.obj);
    },
  },
  mounted() {
    this.func_get_status();
  },
  computed: {
    ...mapGetters(["USER_STATE"]),
  },
};
</script>

<style>
a:link {
  color: #1b8634;
  text-decoration: blink;
  transition: 0.5s;
}
a:link:hover {
  color: #3a804a;
  cursor: pointer;
}
a:visited {
  color: #1b8634;
}
a:active {
  color: #1b8634;
}
</style>
