<template>
  <div
    class="finance__modal__wrapper"
    :class="[$store.state.user_setting.blur ? 'blur' : 'no-blur']"
  >
    <div class="finance_modal__content">
      <div class="header__back_btn">
        <div @click="$emit('close_modal')" class="arrow-7">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="header__title">
        <h2
          :style="{
            color: type === 1 ? '#29e5a5' : '#ff7575',
          }"
        >
          Добавить {{ type === 1 ? "приход" : "расход" }}
        </h2>
      </div>
      <div class="finance__modal__form">
        <form @submit.prevent="func_send_form">
          <div class="form__group">
            <label>Сумма*</label>
            <input type="number" required v-model="summ" />
          </div>
          <div class="form__group">
            <label>Пометка</label>
            <input type="text" v-model="comment" />
          </div>
          <div class="money__data">
            <div
              class="money__item"
              v-for="item in money"
              :key="item.id"
              @click="func_select(item)"
              :style="{
                color: item.select
                  ? type === 1
                    ? 'rgb(41, 229, 165)'
                    : 'rgb(255, 117, 117)'
                  : '#dbdbdb',
              }"
            >
              <i class="fa" :class="item.icon"></i>
            </div>
          </div>
          <button>Добавить</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    type: Number,
  },
  data() {
    return {
      summ: 0,
      comment: "",
      money: [
        { id: 1, icon: "fa-credit-card-alt", select: true },
        { id: 2, icon: "fa fa-money", select: false },
      ],
    };
  },
  methods: {
    func_select(obj) {
      this.money.forEach(function (item) {
        item.select = false;
      });
      obj.select = true;
    },
    func_send_form() {
      let amount_id = this.money.find((x) => x.select === true)
        ? this.money.find((x) => x.select === true).id
        : 0;
      if (amount_id === 0) return;

      let json = {
        type: this.type,
        summ: this.summ,
        comment: this.comment,
        amount_type: amount_id,
      };
      this.$sendRequest("order.php/save_prix_rasx", json).then((result) => {
        if (!result) return;
        let user_state = { ...this.USER_STATE };
        user_state.amount_card = result.amount_card;
        user_state.amount_nal = result.amount_nal;

        this.$store.commit("CHANGE_USER", user_state);
        this.$emit("close_modal_refresh");
      });
    },
  },
  computed: {
    ...mapGetters(["USER_STATE"]),
  },
};
</script>

<style></style>
