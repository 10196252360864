<template>
  <div class="step5">
    <div class="step__header">
     
    
      <div class="step__header3">
        Минимальная сумма заказа 2000 руб
      </div>

 <div class="step5__header2">
        Вступайте в нашу группу телеграмм
      </div>
<div class="step5__header2">
        там мы публикуем фото-отчеты и информируем новостями
      </div>


<a @click="func_show_support">
<img class="teleg-tm" :src="require('@/assets/qrtgg.png')" /> </a>

<a @click="func_show_support">
<img class="teleg-tm2" :src="require('@/assets/tgmb.png')" /> </a>


<div class="step5__header3" :src="require('@/assets/tgmb.png')">
        @pcbchina1
      </div>


    </div>
    <button class="step_bnt" @click="$router.push('/')">Хорошо</button>
  </div>
</template>

<script>
export default {


   methods: {
   func_show_support() {
      window.open("https://pcbchina.ru/contacts/", "_blank");
    },
 }
};

</script>

<style></style>
