<template>
  <div class="pay__wrapper">
    <div class="pay__content">
      <div @click="$emit('close')" class="close_pay">
        <i aria-hidden="true" class="fa fa-close"></i>
      </div>
      <div class="pay_block">
        <div class="pay_row">
          <div class="mol-lg-4 mol-xl-6 mol-md-6">
            <div class="pay-table-c">
              <div class="paycbp-a">
                <div class="paycbp-t">Оплата переводом</div>
               
                <div class="paycbp-summ">{{ order.price }}</div>

                <div class="paycbp-text">
                  Номер карты сбербанк:
                  <div class="paycbp-text-u">{{ card_num }}</div>
                </div>

                <div class="paycbp-text">
                  Получатель:
                  <div class="paycbp-text-u">{{ card_name }}</div>
                </div>
               

                <div class="paycbp-text-all">
                Не указывайте комментарий к переводу! <br>
                  После оплаты пришлите скриншот чека и номер заказа
                </div>
                <div class="paycbp-text-all-m">
                  Нам на почту :
                  <div class="paycbp-text-all-b">pcbchina@mail.ru</div>
                </div>
                <div class="paycbp-text-all-m">
                  Телеграм :
                  <div class="paycbp-text-all-b">@pcbchina</div>
                </div>
                <div class="paycbp-text-all-m">
                  WhatsApp :
                  <div class="paycbp-text-all-b">8 993 900 00 93</div>
                </div>
              </div>
            </div>
          </div>

          <div class="mol-lg-4 mol-xl-6 mol-md-6">
            <div class="pay-table-c border-r">
              <div class="paycbp-a">
                <div class="paycbp-t">На юр. счет (Безнал)</div>
                <div class="paycbp-summ">
                  {{
                    Number(
                      Number(order.price) + Number(order.price) * 0.2
                    ).toFixed(2)
                  }}
                </div>

                <div class="paycbp-text-all-ur">
                  Сумма увеличена за дополнительные сложности и обработку
                  документов. Пришлите реквизиты вашей организации, в ответ мы
                  отправим счет на оплату. В письме укажите номер заказа
                  <a class="paycbp-text-all-num"> # {{ order.order_id }}</a>
                </div>

                <div class="paycbp-text-all-m">
                  Нам на почту :
                  <div class="paycbp-text-all-b">pcbchina@mail.ru</div>
                </div>
                <div class="paycbp-text-all-m">
                  Телеграм :
                  <div class="paycbp-text-all-b">@pcbchina</div>
                </div>
                <div class="paycbp-text-all-m">
                  WhatsApp :
                  <div class="paycbp-text-all-b">8 993 900 00 93</div>
                </div>
              </div>
            </div>
          </div>

          <div class="mol-lg-4 mol-xl-6 mol-md-6">
            <div class="pay-table-c border-r">
              <div class="paycbp-a">
                <div class="paycbp-t">Оплата онлайн</div>
                <div class="paycbp-summ">
                  {{
                    Number(
                      Number(order.price) + Number(order.price) * 0.15
                    ).toFixed(2)
                  }}
                </div>
                <div class="paycbp-text-all-ur">
                  Сумма увеличена изза комиссии банков и интернет-эквайринга.
                </div>
                <div class="but-pay" type="button" @click="func_pay">
                  Оплатить онлайн
                </div>
              </div>

              <br />
              <img
                :src="require('@/assets/pay.png')"
                alt=""
                class="img_pay"
              /><br />
            </div>
          </div>

          <div class="mol-lg-4 mol-xl-6 mol-md-6"></div>
        </div>
      </div>
      <div class="text-cent-pay">
        *Способ оплаты не влияет на скорость обработки заказов
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: Object,
  },
  data() {
    return {
      card_num: "",
      card_name: "",
    };
  },
  methods: {
    func_pay() {
      let summ = Number(
        Number(this.order.price) + Number(this.order.price) * 0.15
      ).toFixed(2);

      let url = "order.php/payment";
      this.$sendRequest(url, {
        amount: summ,
        order_id: this.order.order_id,
      }).then((result) => {
        if (!result) return;
        if (!result.link) return;
        window.open(result.link, "_blank");
      });
    },
    func_get_card() {
      this.$sendRequest("order.php/get_card", {
        order_id: this.order.order_id,
      }).then((result) => {
        this.card_name = result.name;
        this.card_num = result.num;
      });
    },
  },
  mounted() {
    this.func_get_card();
  },
};
</script>

<style></style>
