<template>
  <div class="products__table__wrapper">
    <div
      class="close__products tooltip"
      @click="$router.push('/')"
      tooltip="Назад"
      tooltip-position="bottom left"
    >
      <i class="fa fa-times" aria-hidden="true"></i>
    </div>

    <div class="products__content">
      <div class="mak">
        <button
          slot="reference"
          type="button"
          class="green1 btnadd-ord btnlg-ord"
          :class="{ cur: this.mak == 'Один макет PCB' }"
          @click="func_select_mak('Один макет PCB')"
        >
          <img
            :src="require('@/assets/one.png')"
            width="50px"
            class="verm mr5"
          />
          <span class="ver2">Один макет PCB</span>
        </button>

        <button
          slot="reference"
          type="button"
          class="green1 btnadd-ord btnlg-ord"
          :class="{ cur: this.mak == 'Оптовый заказ PCB' }"
          @click="func_select_mak('Оптовый заказ PCB')"
        >
          <img
            :src="require('@/assets/opt.png')"
            width="50px"
            class="verm mr5"
          />
          <span class="ver2">Оптовый заказ PCB</span>
        </button>

        <button
          slot="reference"
          type="button"
          class="green1 btnadd-ord btnlg-ord"
          :class="{ cur: this.mak == 'Другой заказ' }"
          @click="func_select_mak('Другой заказ')"
        >
          <img
            :src="require('@/assets/dr.png')"
            width="50px"
            class="verm mr5"
          />
          <span class="ver2">Другой заказ</span>
        </button>
      </div>
    </div>
    <transition name="show_product">
      <ProductOne v-if="mak == 'Один макет PCB'" />
      <ProductOpt v-if="mak == 'Оптовый заказ PCB'" />
      <ProductMore v-if="mak == 'Другой заказ'" />
    </transition>
  </div>
</template>

<script>
import ProductOne from "@/components/Products/product_one.vue";
import ProductOpt from "@/components/Products/product_opt.vue";
import ProductMore from "@/components/Products/product_more.vue";
export default {
  components: {
    ProductOne,
    ProductOpt,
    ProductMore,
  },
  data() {
    return {
      mak: "Один макет PCB",
    };
  },
  methods: {
    func_select_mak(mak) {
      this.mak = mak || "";
    },
  },
};
</script>

<style>
.show_product-enter {
  transform: translateX(-100%);
}

.show_product-enter-active {
  transition: 0.5s;
  will-change: transform;
}
.show_product-enter-to {
  transform: translateX(0);
}
.show_product-leave {
  transform: translateX(0);
}
.show_product-leave-active {
  transition: 0.3s;
}

.show_product-leave-to {
  transform: translateX(-150%);
}
</style>
