<template>
  <div class="payment__info">
    <div class="payment__content">
    <div class="paymain">
      <img src="@/assets/errpay.png" alt="" class="payerr" />
    </div>
    <div class="paytext-err">Ошибка оплаты</div>
    <div class="paytext-err-2"> Произошла ошибка при оплате, возможно, на вашей карте недостаточно средств <br>или вы ввели не верные данные. </div>
    <div class="btn_pay_no_okey" @click="$emit('close_pay')">Попробовать еще раз</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.payment__info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}
.payment__info .payment__content {
  width: 50%;
  z-index:5;
  border-radius: 20px;
  padding: 10px;
  background: #fff;
  min-height: 200px;
  position: relative;
}
.payment__info .payment__content .btn__close {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>