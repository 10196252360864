<template>
  <div class="order-table">
    <Notice v-if="notice.show" :text="notice.text" @close="func_close_notice" />

<div class="main-btn-group clearfix">
        <label>Способ доставки</label>
        <div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="green1 btnadd btnlg"
              :class="{
                cur: this.tipdost == 'СДЕК',
              }"
              @click="func_select_tipdost('СДЕК')"
            >
            <img src="@/assets/post1.png" class="img-add-b" />
              <span class="no-ver">СДЕК</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="purple btnadd btnlg"
             :class="{ cur: this.tipdost == 'Почта России' }"
              @click="func_select_tipdost('Почта России')"
            >
             <img src="@/assets/post2.png" class="img-add-b" />
              <span class="no-ver">Почта России</span>
             
            </button>
          </div>
          
          
          
        </div>


      </div>

      <div class="main-btn-group clearfix">
        <label>
          <font style="vertical-align: inherit"
            ><font style="vertical-align: inherit"
              >ФИО:
            </font></font
          >
        </label>
        <div class="input-group" style="display: flex">
          <div class="quote-input el-input el-input--mini" style="width: 62px">
            <input
               type="text"
               style="width:300px"
               class="el-input__inner dost-inner "
            required
            v-model="profile.company"
            @change="func_save_profile"
              
            />
          </div>
          
        </div>
      </div>

      <div class="main-btn-group clearfix">
        <label>
          <font style="vertical-align: inherit"
            ><font style="vertical-align: inherit"
              >Телефон:
            </font></font
          >
        </label>
        <div class="input-group" style="display: flex">
          <div class="quote-input el-input el-input--mini" style="width: 62px">
            <input
             :mask="['+7 (###) ###-##-##', '+7 (###) ###-##-##']"
               type="text"
               style="width:300px"
               class="el-input__inner dost-inner "
                placeholder="+7 (000) 000 00 00"
            required
            v-model="profile.phone"
            @change="func_save_profile"
              
            />
          </div>
          
        </div>
      </div>

      <div class="main-btn-group clearfix">
        <label>
          <font style="vertical-align: inherit"
            ><font style="vertical-align: inherit"
              >Адрес доставки:
            </font></font
          >
        </label>
        <div class="input-group" style="display: flex">
          <div class="quote-input el-input el-input--mini" style="width: 62px">
            <input
               type="text"
               style="width:500px"
               class="el-input__inner dost-inner "
            required
            v-model="profile.city"
            @change="func_save_profile"
              
            />
          </div>
          
          <div
            class="mask-text-dil"
            v-if="tipdost != 'СДЕК'"
          >
            Укажите полный адрес доставки и индекс. 
            Доставка оплачивается при получении по тарифу Почты России.
            <p style=" color: #bf4646; font-weight:500;"> Внимание! Платная доставка! Долгая доставка!</p>
          </div>
          <div class="mask-text-dil" v-else>
            Укажите адрес пунта выдачи СДЕК. Адреса пунктов выдачи 
            <div class="sdek" @click="func_show_sdek">можно посмотреть тут</div>
         
          </div>
        </div>
        
      </div>
<br>
     <hr>
<br>

     <div class="header__product__title">
      <h4 class="h4-add">Покупка вашего заказа в вашем аккаунте на других ресурсах</h4>
    </div>
    <div class="main-btn-group clearfix">
      <label>
        <font style="vertical-align: inherit"
          ><font style="vertical-align: inherit">Ресурс (Ссылка): </font></font
        >
      </label>
      <div class="input-group" style="display: flex">
        <div class="quote-input el-input el-input--mini" style="width: 150px">
          <input
            type="text"
            autocomplete="off"
            name="purchaseNumber"
            property="1"
            maxlength="100"
            class="el-input__inner left-tx"
            v-model="service"
            required
          />
        </div>
      </div>
    </div>

    <div class="main-btn-group clearfix">
      <label>
        <font style="vertical-align: inherit"
          ><font style="vertical-align: inherit">Логин: </font></font
        >
      </label>
      <div class="input-group" style="display: flex">
        <div class="quote-input el-input el-input--mini" style="width: 150px">
          <input
            type="text"
            autocomplete="off"
            name="purchaseNumber"
            property="1"
            maxlength="100"
            class="el-input__inner left-tx"
            v-model="loginps"
            required
          />
        </div>
      </div>
    </div>

    <div class="main-btn-group clearfix">
      <label>
        <font style="vertical-align: inherit"
          ><font style="vertical-align: inherit">Пароль: </font></font
        >
      </label>
      <div class="input-group" style="display: flex">
        <div class="quote-input el-input el-input--mini" style="width: 150px">
          <input
            type="text"
            autocomplete="off"
            name="purchaseNumber"
            property="1"
            maxlength="100"
            class="el-input__inner left-tx"
            v-model="password"
            required
          />
        </div>
      </div>
    </div>
  <h4 class="h4-add" style="
    font-size: 10px;">Когда заказ будет изготовлен и отправлен на наш склад в Китае, укажите номер отправления по Китаю. <p>В заказе нажмите «Подробнее» введите номер отправления и нажмите «сохранить» </p></h4>

    <div class="main-btn-group clearfix">
      <label> Ваши пожелания </label>
      <div class="dot-set-a">
        <div class="dop-set">
          <textarea
            style="width: 100%"
            rows="3"
            v-model="setting_info"
          ></textarea>
        </div>
      </div>
    </div>

    <div class="group__list" style=" margin-top: 10px; ">
      <div class="down-l" @click="func_update_gerber()">
        <div class="icon">
          <i aria-hidden="true" class="fa fa-cloud-upload"></i>
        </div>
        <div class="text">Загрузить файл</div>
      </div>
      <input
        type="file"
        id="file"
        ref="file"
        v-on:change="func_file_upload()"
        style="display: none"
        accept=".zip"
      />
      <div class="finish-work" v-if="this.file != '' && !this.loader_file">
        <div class="finish-work-line">
          <div class="finish-work-f" :style="{ width: loader + '%' }"></div>
        </div>
      </div>

      <div class="text-download" v-if="this.file == ''">Загрузите файл</div>
      <div class="text-download2" v-if="this.file == ''">
        Максимальный размер файла 10мб
      </div>
      <div class="text-download2" v-if="this.file == ''">
       ( Необязательно )
      </div>
      <div class="text-down-finish" v-if="this.file != '' && this.loader_file">
        Загружено: {{ this.file.name }}
        <a class="del-file" @click="func_delete_file()">
          <i aria-hidden="true" class="fa fa-times"></i
        ></a>
      </div>
      <br />
    </div>

    <div class="header__product__title" style="padding-top: 10px">
      <div
        :style="{ display: send_order ? 'none' : 'block' }"
        class="send-ord"
        @click="func_set_order"
      >
        <div class="icon">
          <i aria-hidden="true" class="fa fa-angle-double-down"></i>
        </div>

        <div class="text">Создать заказ</div>
      </div>

      <div
        class="send-ord-no"
        :style="{ display: send_order ? 'flex' : 'none' }"
      >
        <div class="icon">
          <div class="loader__wrapper"><div class="loading1"></div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notice from "@/components/notification.vue";
export default {
  components: {
    Notice,
  },
  data() {
    return {
       profile: {},
      setting_info: "",
      loginps: "",
      password: "",
      service: "",
      tipdost: "СДЕК",
      file: "",
      loader: 0,
      loader_file: false,
      send_order: false,
      mak: "Другой заказ",
      notice: {
        show: false,
        text: "",
      },
      interval: null,
    };
  },
  methods: {

    
 func_get_profile() {
      this.progress = true;
      let url = "order.php/get_profile";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.profile = result;
        this.progress = false;
      });
    },
func_save_profile() {
      let url = "order.php/save_profile";
      this.$sendRequest(url, this.profile);
    },
    func_save_title() {
      let url = "order.php/save_profile_title";
      this.$sendRequest(url, {
        title: this.profile.title,
      }).then((result) => {
        if (!result) return;
        if (result.success) this.msg = result.msg;
        setTimeout(() => {
          this.msg = "";
        }, 2000);
      });
    },
    func_show_sdek() {
      window.open("https://www.cdek.ru/ru/offices", "_blank");
    },


    func_file_upload() {
      this.file = this.$refs.file.files[0];
      if (this.file.size / 1024 / 1024 > 10) {
        this.file = "";
        this.loader = 0;
        this.loader_file = false;
        this.notice = {
          show: true,
          text: "Файл больше 10 мб",
        };
        return;
      }
      if (!this.file) return;
      this.loader = 0;
      this.loader_file = false;
      this.interval = setInterval(() => {
        this.loader += 1;
        if (this.loader >= 100) {
          this.loader = 100;
          this.loader_file = true;
          clearInterval(this.interval);
        }
      }, 10);
    },
    func_set_order() {
      if (!this.loginps || !this.password || !this.service) {
        this.notice = {
          show: true,
          text: "Заполните поля 'Сервис, Логин, Пароль'",
        };
        return;
      }

      this.send_order = true;
      let json = {
        mak: this.mak,
        setting_info: this.setting_info,
        file: this.file,
          tipdost: this.tipdost,
        loginps: this.loginps,
        password: this.password,
        service: this.service,
        path: "files",
      };
      this.$sendRequest("order.php/save_order", json).then((result) => {
        if (result) {
          this.func_delete_file();
          this.$router.push("/");
        }
      });
    },
     func_select_tipdost(tipdost) {
      this.tipdost = tipdost || "";
    },
    func_delete_file() {
      this.file = "";
      this.loader = 0;
      this.loader_file = false;
    },
    func_update_gerber() {
      document.querySelector("#file").setAttribute("type", "text");
      document.querySelector("#file").setAttribute("type", "file");
      document.querySelector("#file").click();
    },
    func_close_notice() {
      this.notice = {
        show: false,
        text: "",
      };
    },
  },
   mounted() {
    this.func_get_profile();
  },
};
</script>

<style>
</style>