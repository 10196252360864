<template>
  <div class="notice__wrap">
    {{ NOTICE_MSG_STATE }}
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["NOTICE_MSG_STATE"]),
  },
};
</script>

<style>
.notice__wrap {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 20px;
  background: #fff;
  width: 30%;
  height: 10%;
  box-shadow: 0 0 22px black;
  font-size: 20px;
}
</style>