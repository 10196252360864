<template>
  <div class="step4">
    <div class="step__header">
      <h2>Супер! Теперь добавим мастеров</h2>
    </div>
    <div class="step__form_add">
      <form @submit.prevent="func_save_master">
        <input type="text" required v-model="master" />
        <button><i class="fa fa-plus"></i></button>
      </form>
    </div>
    <div class="masters">
      <div class="master__item" v-for="(item, index) in masters" :key="index">
        <span>{{ item.value }}</span>
        <button @click="func_remove_master(item)">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
    <div class="info">
      <p>Имена мастеров будут отображаться в квитанциях и всей системе.</p>
      <p>Так вы сможете запомнить кто выполняет заказ.</p>
      <p>Если вы работаете на себя, можете добавить только свое имя.</p>
      <p>
        В настройках в любой момент можно добавить, удалить или изменить
        мастеров.
      </p>
    </div>
    <p class="error" v-if="error">Добавьте хотя бы одного мастера</p>
    <div class="buttons">
      <button class="step_bnt" @click="func_update_step(3)">&lt;&lt; Назад</button>
      <button class="step_bnt" @click="func_update_step(5)">Продолжить &gt;&gt;</button>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    masters: [],
    master: "",
    error: false,
  }),
  methods: {
    func_update_step(step) {
      if (!step) return;
      if (step === 5) {
        if (this.masters.length > 0) {
          this.$emit("change_step", step);
        } else {
          this.error = true;
        }
      } else {
        this.$emit("change_step", step);
      }
    },
    func_save_master() {
      this.error = false;
      this.$sendRequest("order.php/add_master", {
        value: this.master,
      }).then((result) => {
        if (!result) return;
        this.masters.push(result);
        this.master = "";
      });
    },
    func_get_masters() {
      this.$sendRequest("order.php/get_masters", {}).then((result) => {
        if (!result) return;
        this.masters = result;
      });
    },
    func_remove_master(master) {
      this.$sendRequest("order.php/remove_master", {
        id: master.id,
      }).then(() => {
        this.masters.splice(
          this.masters.findIndex((x) => x.id === master.id),
          1
        );
      });
    },
  },
  mounted() {
    this.func_get_masters();
  },
};
</script>

<style></style>
