<template>
  <div class="data__table__clear" v-if="!progress && card_list.length === 0">
        <div class="go_arr">
        <span></span>
        <span></span>
        <span></span>

    </div>
<p class="go_arr_p">Добавьте свой первый заказ</p>


    
    
  </div>
</template>

<script>
export default {
  props: {
    progress: Boolean,
    card_list: Array,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
