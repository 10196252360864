<template>
  <div class="payment__info">
    <div class="payment__content">
    <div class="paymain">
      <img src="@/assets/okpay.png" alt="" class="payok" />
    </div>
    <div class="paytext"> Заказ успешно оплачен</div>
    <div class="paytext-2"> Ближайшее время мы обработаем ваш заказ и отправим в производство. Если у нас возникнут вопросы, мы пришем уведомление на ваш e-mail. </div>
    <div class="btn_pay_okey" @click="$emit('close_pay')">Хорошо</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.payment__info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
 

}
.payment__info .payment__content {
  width: 50%;
  border-radius: 20px;
   z-index:5;
  padding: 10px;
  backdrop-filter: blur(2px);
  background: #5c5c5ca6 !important ;
  min-height: 200px;
  position: relative;
}
.payment__info .payment__content .btn__close {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>