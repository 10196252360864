<template>
  <div class="main__wrapper">
    <div class="main__content__no__balance">


      <p>Возникла техническая ошибка, если вы видите данное окно напишите нам pcbchina@mail.ru или позвоните 8 993 90000 93</p>


      <transition name="customers">
        <SettingUser
          v-if="show_setting_user"
          @close_setting_user="show_setting_user = false"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import SettingUser from "@/components/_Pages/setting_user";
export default {
  components: {
    SettingUser,
  },
  data() {
    return {
      show_setting_user: false,
    };
  },
};
</script>

<style></style>
