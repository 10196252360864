<template>
  <div class="order-table">
    <Notice v-if="notice.show" :text="notice.text" @close="func_close_notice" />

    <div class="main-btn-group clearfix">
      <label>Способ доставки</label>
      <div>
        <div class="mr10 inline">
          <button
            slot="reference"
            type="button"
            class="green1 btnadd btnlg"
            :class="{
              cur: this.tipdost == 'СДЕК',
            }"
            @click="func_select_tipdost('СДЕК')"
          >
            <img src="@/assets/post1.png" class="img-add-b" />
            <span class="no-ver">СДЕК</span>
          </button>
        </div>
        <div class="mr10 inline">
          <button
            slot="reference"
            type="button"
            class="purple btnadd btnlg"
            :class="{ cur: this.tipdost == 'Почта России' }"
            @click="func_select_tipdost('Почта России')"
          >
            <img src="@/assets/post2.png" class="img-add-b" />
            <span class="no-ver">Почта России</span>
          </button>
        </div>
      </div>
    </div>

    <div class="main-btn-group clearfix">
      <label>
        <font style="vertical-align: inherit"
          ><font style="vertical-align: inherit">ФИО: </font></font
        >
      </label>
      <div class="input-group" style="display: flex">
        <div class="quote-input el-input el-input--mini" style="width: 62px">
          <input
            type="text"
            style="width: 300px"
            class="el-input__inner dost-inner"
            required
            v-model="profile.company"
            @change="func_save_profile"
          />
        </div>
      </div>
    </div>

    <div class="main-btn-group clearfix">
      <label>
        <font style="vertical-align: inherit"
          ><font style="vertical-align: inherit">Телефон: </font></font
        >
      </label>
      <div class="input-group" style="display: flex">
        <div class="quote-input el-input el-input--mini" style="width: 62px">
          <input
            :mask="['+7 (###) ###-##-##', '+7 (###) ###-##-##']"
            type="text"
            style="width: 300px"
            class="el-input__inner dost-inner"
            placeholder="+7 (000) 000 00 00"
            required
            v-model="profile.phone"
            @change="func_save_profile"
          />
        </div>
      </div>
    </div>

    <div class="main-btn-group clearfix">
      <label>
        <font style="vertical-align: inherit"
          ><font style="vertical-align: inherit">Адрес доставки: </font></font
        >
      </label>
      <div class="input-group" style="display: flex">
        <div class="quote-input el-input el-input--mini" style="width: 62px">
          <input
            type="text"
            style="width: 500px"
            class="el-input__inner dost-inner"
            required
            v-model="profile.city"
            @change="func_save_profile"
          />
        </div>

        <div class="mask-text-dil" v-if="tipdost != 'СДЕК'">
          Укажите полный адрес доставки и индекс. Доставка оплачивается при получении по
          тарифу Почты России.
          <p style="color: #bf4646; font-weight: 500">
            Внимание! Платная доставка! Долгая доставка!
          </p>
        </div>
        <div class="mask-text-dil" v-else>
          Укажите адрес пунта выдачи СДЕК. Адреса пунктов выдачи
          <div class="sdek" @click="func_show_sdek">можно посмотреть тут</div>
        </div>
      </div>
    </div>
    <br />
    <hr />
    <br />

    <div class="header__product__title"></div>
    <div class="table__products">
      <div class="products__flex">
        <div class="group__list">
          <div class="down-l" @click="func_update_gerber()">
            <div class="icon">
              <i aria-hidden="true" class="fa fa-cloud-upload"></i>
            </div>
            <div class="text">Загрузить gerber</div>
          </div>
          <input
            type="file"
            id="file"
            ref="file"
            v-on:change="func_file_upload()"
            style="display: none"
            accept=".zip"
          />
          <div class="finish-work" v-if="this.file != '' && !this.loader_file">
            <div class="finish-work-line">
              <div class="finish-work-f" :style="{ width: loader + '%' }"></div>
            </div>
          </div>

          <div class="text-download" v-if="this.file == ''">
            Загрузите Gerber файлы в архиве .zip
          </div>
          <div class="text-download2" v-if="this.file == ''">
            Максимальный размер файла 10мб
          </div>
          <div class="text-down-finish" v-if="this.file != '' && this.loader_file">
            Загружено: {{ this.file.name }} <br />
            <a class="del-file" @click="func_delete_file()">
              Заменить файл <i aria-hidden="true" class="fa fa-retweet"></i
            ></a>
          </div>
          <br />
          <div class="text-down">
            <div class="text-inf2" style="color: #df5b5b">
              Убедитесь в наличии файла сверловки и outline
            </div>
            <div class="text-inf2">Не называйте файлы русскими словами</div>
            <div class="text-inf2">Не загружайте архивы с длинным названием</div>
            <div class="text-inf2">Не загружайте исходники, только gerber.</div>
          </div>
        </div>
      </div>

      <div class="main-btn-group clearfix">
        <label>
          <font style="vertical-align: inherit"
            ><font style="vertical-align: inherit">Размер платы в мм: </font></font
          >
        </label>
        <div class="input-group" style="display: flex">
          <div class="quote-input el-input el-input--mini" style="width: 62px">
            <input
              type="number"
              autocomplete="off"
              name="purchaseNumber"
              property="1"
              maxlength="9"
              v-model="razx"
              class="el-input__inner"
            />
          </div>
          <div
            class="quote-input el-input el-input--mini"
            style="width: 9px; margin: 6px; font-size: 14px"
          >
            Х
          </div>
          <div class="quote-input el-input el-input--mini" style="width: 62px">
            <input
              type="number"
              autocomplete="off"
              name="purchaseNumber"
              property="1"
              maxlength="9"
              v-model="razy"
              class="el-input__inner"
            />
          </div>
        </div>
      </div>
      <div class="img-add-a">
        <img src="@/assets/raz.png" class="img-add-b" />
      </div>

      <div class="main-btn-group clearfix">
        <label> Материал </label>
        <div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="green1 btnadd btnlg"
              :class="{ cur: this.material == 'FR-4' }"
              @click="func_select_material('FR-4')"
            >
              <img :src="require('@/assets/fr4.png')" width="30px" class="verm mr5" />
              <span class="ver">FR-4</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="purple btnadd btnlg"
              :class="{ cur: this.material == 'Алюминий' }"
              @click="func_select_material('Алюминий')"
            >
              <img :src="require('@/assets/al.png')" width="30px" class="verm mr5" />
              <span class="ver">Алюминий</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="red1 btnadd btnlg"
              :class="{ cur: this.material == 'Медь' }"
              @click="func_select_material('Медь')"
            >
              <img :src="require('@/assets/cu.png')" width="30px" class="verm mr5" />
              <span class="ver">Медь</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="yellow btnadd btnlg"
              :class="{ cur: this.material == 'Шлейф' }"
              @click="func_select_material('Шлейф')"
            >
              <img :src="require('@/assets/sh.png')" width="30px" class="verm mr5" />
              <span class="ver">Шлейф</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="yellow btnadd btnlg"
              :class="{ cur: this.material == 'Роджерс' }"
              @click="func_select_material('Роджерс')"
            >
              <img :src="require('@/assets/rod.png')" width="30px" class="verm mr5" />
              <span class="ver">Роджерс</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="yellow btnadd btnlg"
              :class="{ cur: this.material == 'ПТФЕ' }"
              @click="func_select_material('ПТФЕ')"
            >
              <img :src="require('@/assets/ftpe.png')" width="30px" class="verm mr5" />

              <span class="ver">ПТФЕ</span>
            </button>
          </div>
        </div>
      </div>

      <div class="main-btn-group clearfix">
        <label> Слои</label>

        <div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="green1 btnadd btnlg"
              :class="{ cur: this.sloi == '1-2' }"
              @click="func_select_sloi('1-2')"
            >
              <span class="ver">1-2</span>
            </button>
          </div>

          <div class="mr10 inline" v-if="material == 'FR-4'">
            <button
              slot="reference"
              type="button"
              class="purple btnadd btnlg"
              :class="{ cur: this.sloi == '4' }"
              @click="func_select_sloi('4')"
            >
              <span class="ver">4</span>
            </button>
          </div>
          <div class="mr10 inline" v-if="material == 'FR-4'">
            <button
              slot="reference"
              type="button"
              class="red1 btnadd btnlg"
              :class="{ cur: this.sloi == '6' }"
              @click="func_select_sloi('6')"
            >
              <span class="ver">6</span>
            </button>
          </div>
          <div class="mr10 inline" v-if="material == 'FR-4'">
            <button
              slot="reference"
              type="button"
              class="yellow btnadd btnlg"
              :class="{ cur: this.sloi == '8' }"
              @click="func_select_sloi('8')"
            >
              <span class="ver">8</span>
            </button>
          </div>
          <div class="mr10 inline" v-if="material == 'FR-4'">
            <button
              slot="reference"
              type="button"
              class="blue1 btnadd btnlg"
              :class="{ cur: this.sloi == '10' }"
              @click="func_select_sloi('10')"
            >
              <span class="ver">10</span>
            </button>
          </div>
          <div class="mr10 inline" v-if="material == 'FR-4'">
            <button
              slot="reference"
              type="button"
              class="white btnadd btnlg"
              :class="{ cur: this.sloi == '12' }"
              @click="func_select_sloi('12')"
            >
              <span class="ver">12</span>
            </button>
          </div>
          <div class="mr10 inline" v-if="material == 'FR-4'">
            <button
              slot="reference"
              type="button"
              class="white btnadd btnlg"
              :class="{ cur: this.sloi == '14' }"
              @click="func_select_sloi('14')"
            >
              <span class="ver">14</span>
            </button>
          </div>
        </div>
      </div>

      <div class="main-btn-group clearfix">
        <label>
          <font style="vertical-align: inherit"
            ><font style="vertical-align: inherit">Количество: </font></font
          >
        </label>
        <div class="input-group">
          <div class="quote-input el-input el-input--mini" style="width: 62px">
            <CustomCheckbox
              :p_selected_value="counter"
              @update="func_update_counter"
              class="conunt-add-one"
            />
            <!-- <input
              type="number"
              min="1"
              autocomplete="off"
              name="purchaseNumber"
              property="1"
              maxlength="9"
              v-model="counter"
              class="el-input__inner"
            /> -->
          </div>
          <div class="mask-text">*Минимальное количество 5шт</div>
        </div>
      </div>

      <div class="main-btn-group clearfix">
        <label> Толщина платы</label>

        <div>
          <div class="mr10 inline" v-if="material == 'Шлейф'">
            <button
              slot="reference"
              type="button"
              class="green1 btnadd btnlg"
              :class="{ cur: this.bold == '0.11' }"
              @click="func_select_bold('0.11')"
            >
              <span class="ver">0.11</span>
            </button>
          </div>

          <div class="mr10 inline" v-if="material == 'Шлейф'">
            <button
              slot="reference"
              type="button"
              class="green1 btnadd btnlg"
              :class="{ cur: this.bold == '0.12' }"
              @click="func_select_bold('0.12')"
            >
              <span class="ver">0.12</span>
            </button>
          </div>

          <div class="mr10 inline" v-if="material == 'Шлейф'">
            <button
              slot="reference"
              type="button"
              class="green1 btnadd btnlg"
              :class="{ cur: this.bold == '0.2' }"
              @click="func_select_bold('0.2')"
            >
              <span class="ver">0.2</span>
            </button>
          </div>

          <div class="mr10 inline" v-if="material != 'Шлейф'">
            <button
              slot="reference"
              type="button"
              class="green1 btnadd btnlg"
              :class="{ cur: this.bold == '0.4' }"
              @click="func_select_bold('0.4')"
            >
              <span class="ver">0.4</span>
            </button>
          </div>
          <div class="mr10 inline" v-if="material != 'Шлейф'">
            <button
              slot="reference"
              type="button"
              class="purple btnadd btnlg"
              :class="{ cur: this.bold == '0.6' }"
              @click="func_select_bold('0.6')"
            >
              <span class="ver">0.6</span>
            </button>
          </div>
          <div class="mr10 inline" v-if="material != 'Шлейф'">
            <button
              slot="reference"
              type="button"
              class="red1 btnadd btnlg"
              :class="{ cur: this.bold == '0.8' }"
              @click="func_select_bold('0.8')"
            >
              <span class="ver">0.8</span>
            </button>
          </div>
          <div class="mr10 inline" v-if="material != 'Шлейф'">
            <button
              slot="reference"
              type="button"
              class="yellow btnadd btnlg"
              :class="{ cur: this.bold == '1.0' }"
              @click="func_select_bold('1.0')"
            >
              <span class="ver">1.0</span>
            </button>
          </div>
          <div class="mr10 inline" v-if="material != 'Шлейф'">
            <button
              slot="reference"
              type="button"
              class="blue1 btnadd btnlg"
              :class="{ cur: this.bold == '1.2' }"
              @click="func_select_bold('1.2')"
            >
              <span class="ver">1.2</span>
            </button>
          </div>
          <div class="mr10 inline" v-if="material != 'Шлейф'">
            <button
              slot="reference"
              type="button"
              class="white btnadd btnlg"
              :class="{ cur: this.bold == '1.6' }"
              @click="func_select_bold('1.6')"
            >
              <span class="ver">1.6</span>
            </button>
          </div>
          <div class="mr10 inline" v-if="material != 'Шлейф'">
            <button
              slot="reference"
              type="button"
              class="white btnadd btnlg"
              :class="{ cur: this.bold == '2.0' }"
              @click="func_select_bold('2.0')"
            >
              <span class="ver">2.0</span>
            </button>
          </div>
        </div>
      </div>

      <div class="main-btn-group clearfix">
        <label> Мин дорожка \ расстояние между дорожками </label>
        <div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="green1 btnadd btnlg"
              :class="{ cur: this.level == '0.08 мм' }"
              @click="func_select_level('0.08 мм')"
            >
              <span class="ver">0.08 мм</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="purple btnadd btnlg"
              :class="{ cur: this.level == '0.11 мм' }"
              @click="func_select_level('0.11 мм')"
            >
              <span class="ver">0.11 мм</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="red1 btnadd btnlg"
              :class="{ cur: this.level == '0.13 мм' }"
              @click="func_select_level('0.13 мм')"
            >
              <span class="ver">0.13 мм</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="yellow btnadd btnlg"
              :class="{ cur: this.level == '0.16 мм' }"
              @click="func_select_level('0.16 мм')"
            >
              <span class="ver">0.16 мм</span>
            </button>
          </div>
        </div>
      </div>

      <div class="main-btn-group clearfix">
        <label> Мин отверстие </label>
        <div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="green1 btnadd btnlg"
              :class="{ cur: this.min_shere == '0.15 мм' }"
              @click="func_select_min_shere('0.15 мм')"
            >
              <span class="ver">0.15 мм</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="purple btnadd btnlg"
              :class="{ cur: this.min_shere == '0.20 мм' }"
              @click="func_select_min_shere('0.20 мм')"
            >
              <span class="ver">0.20 мм</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="red1 btnadd btnlg"
              :class="{ cur: this.min_shere == '0.25 мм' }"
              @click="func_select_min_shere('0.25 мм')"
            >
              <span class="ver">0.25 мм</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="yellow btnadd btnlg"
              :class="{ cur: this.min_shere == '0.30 мм' }"
              @click="func_select_min_shere('0.30 мм')"
            >
              <span class="ver">0.30 мм</span>
            </button>
          </div>
        </div>
      </div>

      <div class="main-btn-group clearfix">
        <label> Медь </label>
        <div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="green1 btnadd btnlg"
              :class="{ cur: this.med == '1 oz' }"
              @click="func_select_med('1 oz')"
            >
              <span class="ver">1 oz</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="purple btnadd btnlg"
              :class="{ cur: this.med == '2 oz' }"
              @click="func_select_med('2 oz')"
            >
              <span class="ver">2 oz</span>
            </button>
          </div>
        </div>
      </div>

      <div class="main-btn-group clearfix">
        <label> Цвет маски </label>
        <div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="green1 btnadd btnlg"
              :class="{ cur: this.color == 'Зеленый' }"
              @click="func_select_color('Зеленый')"
            >
              <i></i><span class="ver">Зеленый</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="purple btnadd btnlg"
              :class="{ cur: this.color == 'Фиолетовый' }"
              @click="func_select_color('Фиолетовый')"
            >
              <i></i>
              <span class="ver">Фиолетовый</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="red1 btnadd btnlg"
              :class="{ cur: this.color == 'Красный' }"
              @click="func_select_color('Красный')"
            >
              <i></i><span class="ver">Красный</span>
            </button>
          </div>
          <div class="mr10 inline">
            <!----><button
              slot="reference"
              type="button"
              class="yellow btnadd btnlg"
              :class="{ cur: this.color == 'Желтый' }"
              @click="func_select_color('Желтый')"
            >
              <i></i><span class="ver">Желтый</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="blue1 btnadd btnlg"
              :class="{ cur: this.color == 'Синий' }"
              @click="func_select_color('Синий')"
            >
              <i></i><span class="ver">Синий</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="white btnadd btnlg"
              :class="{ cur: this.color == 'Белый' }"
              @click="func_select_color('Белый')"
            >
              <i></i><span class="ver">Белый</span>
            </button>
          </div>
          <div class="inline">
            <button
              slot="reference"
              type="button"
              class="black btnadd btnlg auto-mt15-1340"
              :class="{ cur: this.color == 'Черный' }"
              @click="func_select_color('Черный')"
            >
              <i></i><span class="ver">Черный</span>
            </button>
          </div>
          <div class="mask-text">
            *Для 1-2 слоя плат, маска любого цвета бесплатно. Для 4-х слойных плат и выше,
            любая маска кроме зеленой + от 3000 руб.
          </div>
        </div>
      </div>

      <div class="main-btn-group clearfix">
        <label> Финишное покрытие </label>
        <div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="green1 btnadd btnlg"
              :class="{ cur: this.finish == 'Оловянно-свинцовое' }"
              @click="func_select_finish('Оловянно-свинцовое')"
            >
              <span class="ver">Оловянно-свинцовое</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="purple btnadd btnlg"
              :class="{ cur: this.finish == 'Олово' }"
              @click="func_select_finish('Олово')"
            >
              <span class="ver">Олово</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="purple btnadd btnlg"
              :class="{ cur: this.finish == 'Золото' }"
              @click="func_select_finish('Золото')"
            >
              <span class="ver">Золото</span>
            </button>
          </div>
        </div>
      </div>

      <div class="main-btn-group clearfix">
        <label> </label>
        <div class="finish-text">
          <input type="checkbox" v-model="soglas" />
          Вы соглашаетесь с тем, что мы можем изменить финишное покрытие
          <br />
          по своему усмотрению без дополнительный платы с Оловянно-свинцового/Олово на
          Золото
        </div>
      </div>

      <div class="main-btn-group clearfix">
        <label> Удалить номер заказа </label>
        <div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="green1 btnadd btnlg"
              :class="{ cur: this.noorder == 'Нет' }"
              @click="func_select_noorder('Нет')"
            >
              <span class="ver">Нет</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="purple btnadd btnlg"
              :class="{ cur: this.noorder == 'Да' }"
              @click="func_select_noorder('Да')"
            >
              <span class="ver">Да</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="purple btnadd btnlg"
              :class="{
                cur: this.noorder == 'Разместить в указанном месте',
              }"
              @click="func_select_noorder('Разместить в указанном месте')"
            >
              <span class="ver">Разместить в указанном месте</span>
            </button>
          </div>
          <div class="mask-text" v-if="noorder != 'Разместить в указанном месте'">
            *Мы разместим номер заказа на плате размером 3х15мм, чтобы отличить ее от
            остальных. Удалить номер заказа ~300руб, либо разместим в указаном месте
            бесплатно.
          </div>
          <div class="mask-text-n" v-else>
            *Разместите на плате текcт
            <div class="pcb">JLCJLCJLCJLC</div>
            размером 3х15мм.
          </div>
        </div>
      </div>

      <div class="main-btn-group clearfix">
        <label>Другой дизайн</label>
        <div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="green1 btnadd btnlg"
              :class="{ cur: this.diz == '1' }"
              @click="func_select_diz('1')"
            >
              <span class="ver">1</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="purple btnadd btnlg"
              :class="{ cur: this.diz == '2' }"
              @click="func_select_diz('2')"
            >
              <span class="ver">2</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="red1 btnadd btnlg"
              :class="{ cur: this.diz == '3' }"
              @click="func_select_diz('3')"
            >
              <span class="ver">3</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="yellow btnadd btnlg"
              :class="{ cur: this.diz == '4' }"
              @click="func_select_diz('4')"
            >
              <span class="ver">4</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="blue1 btnadd btnlg"
              :class="{ cur: this.diz == '5' }"
              @click="func_select_diz('5')"
            >
              <span class="ver">5</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="white btnadd btnlg"
              :class="{ cur: this.diz == '6' }"
              @click="func_select_diz('6')"
            >
              <span class="ver">6</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="white btnadd btnlg"
              :class="{ cur: this.diz == '7' }"
              @click="func_select_diz('7')"
            >
              <span class="ver">7</span>
            </button>
          </div>
          <div class="mask-text">
            *Если вы размещаете на одной плате несколько разных дизайнов, завод берет за
            это дополнительную плату.
          </div>
        </div>
      </div>
      <div class="img-add-a">
        <img src="@/assets/diz.png" class="img-add-b" />
      </div>

      <div class="main-btn-group clearfix">
        <label>
          <font style="vertical-align: inherit"
            ><font style="vertical-align: inherit">Панелирование: </font></font
          >
        </label>
        <div class="input-group" style="display: flex">
          <div class="quote-input el-input el-input--mini" style="width: 62px">
            <input
              type="number"
              autocomplete="off"
              name="purchaseNumber"
              property="1"
              maxlength="9"
              v-model="panelx"
              class="el-input__inner"
            />
          </div>
          <div
            class="quote-input el-input el-input--mini"
            style="width: 9px; margin: 6px; font-size: 14px"
          >
            Х
          </div>
          <div class="quote-input el-input el-input--mini" style="width: 62px">
            <input
              type="number"
              autocomplete="off"
              name="purchaseNumber"
              property="1"
              maxlength="9"
              v-model="panely"
              class="el-input__inner"
            />
          </div>
        </div>
      </div>

      <div class="img-add-a">
        <img src="@/assets/panel.png" class="img-add-b" />
        <div class="mask-text">
          *Если вы размещаете на одной плате несколько одинаковых плат, завод берет за это
          дополнительную плату.
        </div>
      </div>

      <div class="main-btn-group clearfix">
        <label> Трафарет </label>
        <div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="green1 btnadd btnlg"
              :class="{ cur: this.trafaret == 'Нет' }"
              @click="func_select_trafaret('Нет')"
            >
              <span class="ver">Нет</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="purple btnadd btnlg"
              :class="{ cur: this.trafaret == 'Верх' }"
              @click="func_select_trafaret('Верх')"
            >
              <span class="ver">Верх</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="purple btnadd btnlg"
              :class="{ cur: this.trafaret == 'Низ' }"
              @click="func_select_trafaret('Низ')"
            >
              <span class="ver">Низ</span>
            </button>
          </div>
          <div class="mr10 inline">
            <button
              slot="reference"
              type="button"
              class="purple btnadd btnlg"
              :class="{ cur: this.trafaret == 'Верх + Низ' }"
              @click="func_select_trafaret('Верх + Низ')"
            >
              <span class="ver">Верх + Низ</span>
            </button>
          </div>

          <div class="main-btn-group clearfix" v-if="trafaret != 'Нет'">
            <div>
              <div class="mask-text">*Укажите размеры трафарета в мм.</div>

           

              <div class="mr10 inline">
                <button
                  slot="reference"
                  type="button"
                  class="green1 btnadd btnlg"
                  :class="{ cur: this.trafaretsize == '50х50' }"
                  @click="func_select_trafaretsize('50х50')"
                >
                  <span class="ver">50х50 (1200р)</span>
                </button>
              </div>

              <div class="mr10 inline">
                <button
                  slot="reference"
                  type="button"
                  class="green1 btnadd btnlg"
                  :class="{ cur: this.trafaretsize == '100х100' }"
                  @click="func_select_trafaretsize('100х100')"
                >
                  <span class="ver">100х100 (1400р)</span>
                </button>
              </div>

              <div class="mr10 inline">
                <button
                  slot="reference"
                  type="button"
                  class="green1 btnadd btnlg"
                  :class="{ cur: this.trafaretsize == '150х150' }"
                  @click="func_select_trafaretsize('150х150')"
                >
                  <span class="ver">150х150 (1400р)</span>
                </button>
              </div>

              <div class="mr10 inline">
                <button
                  slot="reference"
                  type="button"
                  class="green1 btnadd btnlg"
                  :class="{ cur: this.trafaretsize == '200х190' }"
                  @click="func_select_trafaretsize('200х190')"
                >
                  <span class="ver">200х190 (1800р)</span>
                </button>
              </div>

              <div class="mr10 inline">
                <button
                  slot="reference"
                  type="button"
                  class="green1 btnadd btnlg"
                  :class="{ cur: this.trafaretsize == '290х190' }"
                  @click="func_select_trafaretsize('290х190')"
                >
                  <span class="ver">290х190 (2300р)</span>
                </button>
              </div>

              <div class="mr10 inline">
                <button
                  slot="reference"
                  type="button"
                  class="green1 btnadd btnlg"
                  :class="{ cur: this.trafaretsize == '380х280' }"
                  @click="func_select_trafaretsize('380х280')"
                >
                  <span class="ver">380х280 (2800р)</span>
                </button>
              </div>

              <div class="mr10 inline">
                <button
                  slot="reference"
                  type="button"
                  class="green1 btnadd btnlg"
                  :class="{ cur: this.trafaretsize == '420х320' }"
                  @click="func_select_trafaretsize('420х320')"
                >
                  <span class="ver">420х320 (3600р)</span>
                </button>
              </div>

              <div class="mr10 inline">
                <button
                  slot="reference"
                  type="button"
                  class="green1 btnadd btnlg"
                  :class="{ cur: this.trafaretsize == '450х350' }"
                  @click="func_select_trafaretsize('450х350')"
                >
                  <span class="ver">450х350 (4500р)</span>
                </button>
              </div>

              <div class="mr10 inline" style="opacity: 0">
                <button
                  slot="reference"
                  type="button"
                  class="green1 btnadd btnlg"
                  :class="{ cur: this.trafaretsize == '' }"
                  @click="func_select_trafaretsize('')"
                >
                  <span class="ver"></span>
                </button>
              </div>
            </div>
          </div>

          <div class="main-btn-group clearfix" v-if="trafaret != 'Нет'">
            <div>
              <div class="mask-text">*Фреймворк-рамка, дополнительная стоимость от 1000р</div>

           

              <div class="mr10 inline">
                <button
                  slot="reference"
                  type="button"
                  class="green1 btnadd btnlg"
                  :class="{ cur: this.trafaretfreim == 'Без рамки' }"
                  @click="func_select_trafaretfreim('Без рамки')"
                >
                  <span class="ver">Без рамки</span>
                </button>
              </div>

              <div class="mr10 inline">
                <button
                  slot="reference"
                  type="button"
                  class="green1 btnadd btnlg"
                  :class="{ cur: this.trafaretfreim == 'На рамке' }"
                  @click="func_select_trafaretfreim('На рамке')"
                >
                  <span class="ver">На рамке</span>
                </button>
              </div>

              

              
            </div>
          </div>

          <div class="main-btn-group clearfix" v-if="trafaret != 'Нет'">
            <div>
              <div class="mask-text">*Финишная полировка, дополнительная стоимость от 300р</div>

           

              <div class="mr10 inline">
                <button
                  slot="reference"
                  type="button"
                  class="green1 btnadd btnlg"
                  :class="{ cur: this.trafaretpol == 'Шлифование' }"
                  @click="func_select_trafaretpol('Шлифование')"
                >
                  <span class="ver">Шлифование</span>
                </button>
              </div>

              <div class="mr10 inline">
                <button
                  slot="reference"
                  type="button"
                  class="green1 btnadd btnlg"
                  :class="{ cur: this.trafaretpol == 'Травление' }"
                  @click="func_select_trafaretpol('Травление')"
                >
                  <span class="ver">Травление</span>
                </button>
              </div>

              <div class="mr10 inline">
                <button
                  slot="reference"
                  type="button"
                  class="green1 btnadd btnlg"
                  :class="{ cur: this.trafaretpol == 'Электрополировка' }"
                  @click="func_select_trafaretpol('Электрополировка')"
                >
                  <span class="ver">Электрополировка</span>
                </button>
              </div>

              

              
            </div>
          </div>





        </div>
      </div>

      <div class="img-add-a">
        <img src="@/assets/traf.png" class="img-add-b" />
      </div>

      <div class="main-btn-group clearfix">
        <label> Дополнительные настройки </label>
        <div class="dot-set-a">
          <div class="dop-set">
            <textarea style="width: 100%" rows="3" v-model="setting_info"></textarea>
          </div>
        </div>
      </div>

      <div class="header__product__title" style="padding-top: 10px">
        <div
          :style="{ display: send_order ? 'none' : 'block' }"
          class="send-ord"
          @click="func_set_order"
        >
          <div class="icon">
            <i aria-hidden="true" class="fa fa-angle-double-down"></i>
          </div>

          <div class="text">Создать заказ</div>
        </div>

        <div class="send-ord-no" :style="{ display: send_order ? 'flex' : 'none' }">
          <div class="icon">
            <div class="loader__wrapper"><div class="loading1"></div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notice from "@/components/notification.vue";
import CustomCheckbox from "@/components/Products/custom_checkbox.vue";
export default {
  components: {
    Notice,
    CustomCheckbox,
  },
  watch: {
    counter(value) {
      let val = parseInt(value);
      if (val == this.count_end) return;
      setTimeout(() => {
        if (val > 5 && val < 11) {
          this.counter = 10;
        } else if (val < 5) {
          this.counter = 5;
        } else {
          this.counter = Math.ceil(val / 5) * 5;
        }
        this.count_end = this.counter;
      }, 1000);
    },
  },
  data() {
    return {
      profile: {},
      notice: {
        show: false,
        text: "",
      },
      count_end: 0,
      mak: "Один макет PCB",
      material: "FR-4",
      counter: 5,
      price: "",
      razx: "",
      razy: "",
      panelx: 1,
      panely: 1,
      sloi: "1-2",
      bold: "1.6",
      diz: "1",
      level: "0.16 мм",
      min_shere: "0.30 мм",
      med: "1 oz",
      color: "Зеленый",
      finish: "Оловянно-свинцовое",
      noorder: "Нет",
      tipdost: "СДЕК",
      trafaret: "Нет",
      trafaretsize: "",
      trafaretfreim: "Без рамки",
      trafaretpol: "Шлифование",
      sbor: "Нет",
      setting_info: "",
      soglas: false,
      file: "",
      loader: 0,
      loader_file: false,
      interval: null,
      send_order: false,
    };
  },
  methods: {
    func_get_profile() {
      this.progress = true;
      let url = "order.php/get_profile";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.profile = result;
        this.progress = false;
      });
    },
    func_save_profile() {
      let url = "order.php/save_profile";
      this.$sendRequest(url, this.profile);
    },
    func_save_title() {
      let url = "order.php/save_profile_title";
      this.$sendRequest(url, {
        title: this.profile.title,
      }).then((result) => {
        if (!result) return;
        if (result.success) this.msg = result.msg;
        setTimeout(() => {
          this.msg = "";
        }, 2000);
      });
    },
    func_close_notice() {
      this.notice = {
        show: false,
        text: "",
      };
    },
    func_update_counter(val) {
      this.counter = val;
    },
    func_show_rast() {
      window.open("https://pcbchina.ru/opt/", "_blank");
    },
    func_show_sdek() {
      window.open("https://www.cdek.ru/ru/offices", "_blank");
    },
    func_select_material(material) {
      this.material = material || "";
    },
    func_select_bold(bold) {
      this.bold = bold || "";
    },
    func_select_diz(diz) {
      this.diz = diz || "";
    },
    func_select_sloi(sloi) {
      this.sloi = sloi || "";
    },
    func_select_level(level) {
      this.level = level || "";
    },
    func_select_min_shere(min_shere) {
      this.min_shere = min_shere || "";
    },
    func_select_med(med) {
      this.med = med || "";
    },
    func_select_color(color) {
      this.color = color || "";
    },
    func_select_finish(finish) {
      this.finish = finish || "";
    },
    func_select_noorder(noorder) {
      this.noorder = noorder || "";
    },
    func_select_tipdost(tipdost) {
      this.tipdost = tipdost || "";
    },
    func_select_trafaret(trafaret) {
      this.trafaret = trafaret || "";
    },
    func_select_trafaretsize(trafaretsize) {
      this.trafaretsize = trafaretsize || "";
    },
    func_select_trafaretpol(trafaretpol) {
      this.trafaretpol = trafaretpol || "";
    },
    func_select_trafaretfreim(trafaretfreim) {
      this.trafaretfreim = trafaretfreim || "";
    },
    func_select_sbor(sbor) {
      this.sbor = sbor || "";
    },
    func_file_upload() {
      this.file = this.$refs.file.files[0];
      if (this.file.size / 1024 / 1024 > 10) {
        this.file = "";
        this.loader = 0;
        this.loader_file = false;
        this.notice = {
          show: true,
          text: "Файл больше 10 мб",
        };
        return;
      }
      if (!this.file) return;
      this.loader = 0;
      this.loader_file = false;
      this.interval = setInterval(() => {
        this.loader += 1;
        if (this.loader >= 100) {
          this.loader = 100;
          this.loader_file = true;
          clearInterval(this.interval);
        }
      }, 10);
    },
    func_update_gerber() {
      document.querySelector("#file").setAttribute("type", "text");
      document.querySelector("#file").setAttribute("type", "file");
      document.querySelector("#file").click();
    },
    func_delete_file() {
      this.file = "";
      this.loader = 0;
      this.loader_file = false;
    },
    func_set_order() {
      if (!this.file) {
        this.notice = {
          show: true,
          text: "Выберите файл",
        };
        return;
      }
      if (!this.razx || !this.razy) {
        this.notice = {
          show: true,
          text: "Заполните размер платы",
        };
        return;
      }
      this.send_order = true;
      let json = {
        material: this.material,
        mak: this.mak,
        counter: this.counter,
        price: this.func_get_price(),
        razx: this.razx,
        razy: this.razy,
        panelx: this.panelx,
        panely: this.panely,
        bold: this.bold,
        diz: this.diz,
        sloi: this.sloi,
        level: this.level,
        min_shere: this.min_shere,
        med: this.med,
        color: this.color,
        finish: this.finish,
        noorder: this.noorder,
        tipdost: this.tipdost,
        trafaret: this.trafaret,
        trafaretsize: this.trafaretsize,
        trafaretfreim: this.trafaretfreim,
        trafaretpol: this.trafaretpol,
        sbor: this.sbor,
        setting_info: this.setting_info,
        soglas: this.soglas,
        file: this.file,
        path: "files",
      };
      this.$sendRequest("order.php/save_order", json).then((result) => {
        if (result) {
          this.func_delete_file();
          this.$router.push("/");
        }
      });
    },
    func_get_price() {
      let result = 0;
      if (
        this.func_get_default() &&
        Number(this.razx) < 50 &&
        Number(this.razy) < 50 &&
        Number(this.counter) == 5 &&
        (this.bold == "1.0" || this.bold == "1.2" || this.bold == "1.6")
      ) {
        result = 2000;
      } else if (
        this.func_get_default() &&
        Number(this.razx) >= 50 &&
        Number(this.razx) < 100 &&
        Number(this.razy) >= 50 &&
        Number(this.razy) < 100 &&
        Number(this.counter) == 5 &&
        (this.bold == "1.0" || this.bold == "1.2" || this.bold == "1.6")
      ) {
        result = 2000;
      } else if (
        this.func_get_default() &&
        Number(this.razx) < 50 &&
        Number(this.razy) < 50 &&
        Number(this.counter) == 10 &&
        (this.bold == "1.0" || this.bold == "1.2" || this.bold == "1.6")
      ) {
        result = 2300;
      } else if (
        this.func_get_default() &&
        Number(this.razx) >= 50 &&
        Number(this.razx) < 100 &&
        Number(this.razy) >= 50 &&
        Number(this.razy) < 100 &&
        Number(this.counter) == 10 &&
        (this.bold == "1.0" || this.bold == "1.2" || this.bold == "1.6")
      ) {
        result = 2300;
      }
      return result;
    },
    func_get_default() {
      let result = false;
      if (
        this.material == "FR-4" &&
        +this.panelx == 1 &&
        +this.panely == 1 &&
        this.sloi == "1-2" &&
        this.diz == "1" &&
        this.level == "0.16 мм" &&
        this.min_shere == "0.30 мм" &&
        this.med == "1 oz" &&
        this.color == "Зеленый" &&
        this.finish == "Оловянно-свинцовое" &&
        this.noorder == "Нет" &&
        this.tipdost == "СДЕК" &&
        this.trafaret == "Нет" &&
        this.trafaretsize == "" &&
        this.trafaretfreim == "Без рамки" &&
        this.trafaretpol == "Шлифование" &&
        this.sbor == "Нет"
      ) {
        result = true;
      }
      return result;
    },
  },
  mounted() {
    this.func_get_profile();
  },
};
</script>

<style></style>
