<template>
  <div>
    <div class="step2">
      <div class="step__header">
        <h3>Заполните адрес доставки</h3>
      </div>
      <div class="step__header1">
        Для доставки Почта РФ укажите полный адрес и индекс
      </div>
      <div class="step__header2">
        Для доставки СДЕК укажите ближайший пункт выдачи
      </div>
      <div class="step__header3">
        Бесплатная доставка в любую точку РФ
      </div>
      
      <div class="step__form">
        <form @submit.prevent="func_save_info_user">
          <div
            class="registry__form_input"
            :class="{ error_input: errors.includes(1) }"
          >
            <input
              type="text"
              required
              placeholder="Ваше ФИО"
              v-model="company"
            />
          </div>
          
          <div
            class="registry__form_input"
            required
            :class="{ error_input: errors.includes(3) }"
          >
            <input 
            type="number" 
            placeholder="8 999 999 99 99" 
            v-model="phone" />
          </div>

          <textarea rows="3" 
          class="addres-reg"
             type="text"
            required
            :class="{ error_input: errors.includes(2) }"
            v-model="city"
           placeholder="Адрес доставки"
            
            ></textarea>
          <button>Далее >></button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    company: "",
    city: "",
    phone: "",
    errors: [],
  }),
  methods: {
    func_save_info_user() {
      this.errors = [];
      if (!this.company) this.errors.push(1);
      if (!this.city) this.errors.push(2);
      if (!this.phone) this.errors.push(3);

      if (this.errors.length > 0) return;

      this.$sendRequest("order.php/save_profile_registry", {
        company: this.company,
        city: this.city,
        phone: this.phone,
      }).then((result) => {
        if (result && result.success) this.$emit("change_step", 5);
        else this.errors = [1, 2, 3];
      });
    },
    func_get_profile() {
      this.$sendRequest("order.php/get_profile_registry", {}).then((result) => {
        if (!result) return;
        this.company = result.company;
        this.city = result.city;
        this.phone = result.phone;
      });
    },
  },
  mounted() {
    this.func_get_profile();
  },
};
</script>

<style></style>
